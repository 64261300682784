import { useCallback } from "react";
import { RouteChildrenProps, useHistory, useLocation } from "react-router-dom";

import AppRoute from "./AppRoute";
import { RouteConfig } from "./routes";

export interface ModalRouteProps
  extends Omit<RouteConfig, "routes" | "children" | "modal" | "children"> {}

/**
 * A set of properties for a component rendered by modal route.
 */
export interface AppModalRouteComponentProps extends RouteChildrenProps {
  /**
   * Helper function provided to every component rendered by `AppModalRoute`.
   * Closes the modal by navigating to modal's underlying (parent) route.
   *
   * E.g. if modal's path is "/modal" and full route for rendering modal is "app/home/modal",
   * closeAppRouteModal() will navigate to "app/home" route (modal's parent route).
   */
  closeAppRouteModal: () => void;
}

const AppModalRoute = ({
  path = "",
  redirect,
  private: isPrivate,
  component: Component,
}: ModalRouteProps) => {
  const location = useLocation();
  const history = useHistory();

  // Format leading slash character for modal subroute:
  const modalPath = (path as string).replace(/\/\//g, "/");
  // Get path of the underlying (parent) route that is rendered "behind" modal's view:
  const modalParentPath = location.pathname.replace(modalPath, "");
  // Generate the path to match any current route ending with modal's path:
  const routePath = `${modalParentPath}${modalPath}`;

  const closeAppRouteModal: AppModalRouteComponentProps["closeAppRouteModal"] = useCallback(
    () => history.push(modalParentPath),
    [modalParentPath],
  );

  if (!path) return null;

  return (
    <AppRoute
      redirect={redirect}
      private={isPrivate}
      path={routePath}
      children={(props) => {
        if (!props.match) return null;

        return Component && <Component closeAppRouteModal={closeAppRouteModal} {...props} />;
      }}
    />
  );
};

export default AppModalRoute;
