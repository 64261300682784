import { createReducer } from "deox";

import { OrganisationSearchedData } from "@APP/types";

import { resetOrgSearchResults, setOrganisationSearchResults } from "../actions";

export interface OrgState {
  organisations: {
    orgResults: OrganisationSearchedData | null;
  };
}

const defaultState: OrgState = {
  organisations: {
    orgResults: null,
  },
};

const organisationReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setOrganisationSearchResults, (state, { payload }) => ({
    ...state,
    organisations: { ...state.organisations, orgResults: payload },
  })),

  handleAction(resetOrgSearchResults, (state) => ({
    ...state,
    organisations: defaultState.organisations,
  })),
]);

export default organisationReducer;
