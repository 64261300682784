import { ThemeOptions, darken } from "@mui/material";

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
      contained: {
        backgroundColor: "#DE1927",
        "&:hover": {
          backgroundColor: darken("#DE1927", 0.2),
        },
      },
    },
  },
};

export default components;
