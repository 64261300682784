import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Breakpoint } from "@mui/system";

import { AppSessionStorage } from "@APP/services";

import AlertModal from "./AlertModal";

interface AlertButton {
  text?: string;
  onClick?: (value?: string) => void;
}

interface AlertOptions {
  title?: string;
  message?: string;
  buttons?: AlertButton[];
  content?: React.ReactNode;
  // Prevents the modal from closing when navigating to another page.
  persistent?: boolean;
  maxWidth?: Breakpoint;
}

const AlertContext = React.createContext<{
  showAlert: boolean;
  alertOptions?: AlertOptions | null;
  openAlert: (
    title?: string,
    message?: string,
    buttons?: AlertButton[],
    restOptions?: { persistent?: boolean },
  ) => void;
  renderAlert: (content: React.ReactNode, maxWidth?: Breakpoint) => void;
  closeAlert: () => void;
}>({
  showAlert: false,
  alertOptions: null,
  openAlert: () => null,
  renderAlert: () => null,
  closeAlert: () => null,
});

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertOptions, setAlertOptions] = React.useState<AlertOptions | null>(null);

  const onOpenAlert = () => {
    if (AppSessionStorage.getItem("modal.dismissed")) {
      AppSessionStorage.removeItem("modal.dismissed");
    } else {
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (showAlert && !alertOptions?.persistent) closeAlert();
  }, [location]);

  const openAlert = (
    title?: string,
    message?: string,
    buttons?: AlertButton[],
    restOptions?: { persistent?: boolean },
  ) => {
    setAlertOptions({ title, message, buttons, persistent: restOptions?.persistent });
    onOpenAlert();
  };

  const renderAlert = (content: React.ReactNode, maxWidth?: Breakpoint) => {
    setAlertOptions({ content, maxWidth });
    onOpenAlert();
  };

  const closeAlert = () => {
    setAlertOptions(null);
    setShowAlert(false);
  };

  return (
    <AlertContext.Provider value={{ showAlert, alertOptions, openAlert, closeAlert, renderAlert }}>
      <AlertModal />
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
