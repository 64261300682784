import { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";
import { Helmet } from "react-helmet";

interface Props extends BoxProps {
  title?: string;
  children: ReactNode;
}

/**
 * Reusable page container component with ability to manage the web page title.
 */
const Page = ({ title = "", children, ...props }: Props) => {
  return (
    <Box p={3} {...props}>
      <Helmet>
        <title>Bankifi Admin {title ? `| ${title}` : ""}</title>
      </Helmet>
      {children}
    </Box>
  );
};

export default Page;
