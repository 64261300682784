import { colors } from "@mui/material";

import { RTPDeliveryChannel, RTPStatus } from "@APP/types";

export enum ERP_ID {
  XERO = "xero",
  SAGE = "sage",
  QUICKBOOKS = "quickbooks",
  INTERNAL = "internal",
}
export const ERROR_MESSAGES = {
  COMMON: "Something went wrong, please try again later",
};

export const NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION = 0;

export const RTP_STATUS_LABEL: { [key in keyof typeof RTPStatus]: string } = {
  RequestMade: "Request made",
  PaymentSent: "Payment sent",
  PaymentReceived: "Paid",
  Declined: "Declined",
  Created: "Created",
  Scheduled: "Scheduled",
  Cancelled: "Cancelled",
  InProgress: "In progress",
};

export const RTP_STATUS_COLOR: { [key in keyof typeof RTPStatus]: string } = {
  RequestMade: colors.blue[400],
  PaymentSent: colors.lightGreen[400],
  PaymentReceived: colors.green[400],
  Declined: colors.red[400],
  Created: colors.orange[300],
  Scheduled: colors.teal[300],
  Cancelled: colors.blueGrey[400],
  InProgress: colors.yellow[500],
};

export const RTP_DELIVERY_CHANNEL_LABEL: { [key in keyof typeof RTPDeliveryChannel]: string } = {
  Email: "Email",
  Sms: "Sms",
  OwnSms: "SMS from my device",
  FaceToFace: "Face to Face",
  WhatsApp: "WhatsApp",
};

export const commonFormatDate = "dd/MM/yyyy";
export const mediumFormatDate = "EEE dd MMM yyyy";
export const mediumFormatDateTime = "dd MMM yyyy HH:mm";

export const REPORT_PARTNER_COLORS: { [key in keyof typeof Partners]: string } = {
  Incomeing: colors.purple["A700"],
  GoGetPaid: colors.purple["A700"],
  Revenu: colors.grey[500],
  CNCF: colors.red[500],
  // Asb = "#fcbd1b",
};

export const REPORT_CHANNEL_COLORS: {
  [key in keyof typeof RTP_DELIVERY_CHANNEL_LABEL]: {
    [key in keyof typeof REPORT_TRANSACTION_STATUSES]: string;
  };
} = {
  Email: {
    Sent: colors.blue[400],
    Resent: colors.blue[700],
  },
  Sms: {
    Sent: colors.purple[300],
    Resent: colors.purple[700],
  },
  OwnSms: {
    Sent: colors.red[400],
    Resent: colors.red[700],
  },
  FaceToFace: {
    Sent: colors.teal[300],
    Resent: colors.teal[700],
  },
  WhatsApp: {
    Sent: colors.lightGreen[400],
    Resent: colors.lightGreen[700],
  },
};

export const REPORT_TRANSACTION_COLORS: {
  [key in keyof typeof Partners]: {
    [key in keyof typeof REPORT_TRANSACTION_STATUSES]: string;
  };
} = {
  Incomeing: {
    Sent: colors.purple["A700"],
    Resent: colors.purple[300],
  },
  GoGetPaid: {
    Sent: colors.purple["A700"],
    Resent: colors.purple[300],
  },
  Revenu: {
    Sent: colors.grey[500],
    Resent: colors.grey[600],
  },
  CNCF: {
    Sent: colors.red[500],
    Resent: colors.red[600],
  },
  // Asb: {
  //   Sent: colors.orange[300],
  //   Resent: colors.orange[600],
  // },
};

export enum Partners {
  Incomeing = "Incomeing",
  Revenu = "Revenu",
  GoGetPaid = "GoGetPaid",
  CNCF = "CNCF",
}

export enum ReportGroupIntervals {
  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}

export enum REPORT_NAMES {
  channels = "channels",
  declined = "declined",
  peakTraffic = "peakTraffic",
  transactions = "transactions",
  usersDeactive = "usersDeactive",
  usersActive = "usersActive",
  usersRegisteredByBank = "usersRegisteredByBank",
  usersRegisteredByErp = "usersRegisteredByErp",
}

export enum REPORT_FILTERS {
  dates = "selectedDates",
  partner = "selectedPartners",
  status = "selectedStatus",
  channel = "selectedChannels",
  erp = "selectedErps",
  bank = "selectedBanks",
}

export enum REPORT_TRANSACTION_STATUSES {
  Sent = "Sent",
  Resent = "Resent",
}

export enum CHART_FILTER_FIELDS {
  partner = "partner",
  status = "status",
  channel = "channel",
  bank = "bank",
  erp = "erp",
}

export enum TimeBuckets {
  "01:00:00" = "01:00:00",
  "02:00:00" = "02:00:00",
  "03:00:00" = "03:00:00",
  "04:00:00" = "04:00:00",
  "05:00:00" = "05:00:00",
  "06:00:00" = "06:00:00",
  "07:00:00" = "07:00:00",
  "08:00:00" = "08:00:00",
  "09:00:00" = "09:00:00",
  "10:00:00" = "10:00:00",
  "11:00:00" = "11:00:00",
  "12:00:00" = "12:00:00",
  "13:00:00" = "13:00:00",
  "14:00:00" = "14:00:00",
  "15:00:00" = "15:00:00",
  "16:00:00" = "16:00:00",
  "17:00:00" = "17:00:00",
  "18:00:00" = "18:00:00",
  "19:00:00" = "19:00:00",
  "20:00:00" = "20:00:00",
  "21:00:00" = "21:00:00",
  "22:00:00" = "22:00:00",
  "23:00:00" = "23:00:00",
  "00:00:00" = "00:00:00",
}

export enum REPORT_ERPS {
  XERO = "xero",
  SAGE = "sage",
  QUICKBOOKS = "quickbooks",
  INTERNAL = "internal",
}

export enum MetroBankAccountStatuses {
  enabled = "Enabled",
  disabled = "Disabled",
}
