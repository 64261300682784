import { createReducer } from "deox";

import { UserInfoData } from "@APP/types";

import { logout, setAuthenticated, setAuthLoading, setUserData } from "../actions";

export interface AuthState {
  loading: boolean;
  authenticated: boolean;
  user: null | UserInfoData;
}

const defaultState: AuthState = {
  loading: false,
  authenticated: false,
  user: null,
};

const authReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setAuthLoading, (state, { payload }) => ({ ...state, loading: payload })),
  handleAction(setAuthenticated, (state, { payload }) => ({ ...state, authenticated: payload })),
  handleAction(setUserData, (state, { payload }) => ({ ...state, user: payload })),
  handleAction(logout, () => defaultState),
]);

export default authReducer;
