import CONFIG from "./config.json";

interface AppConfig {
  PRODUCT_NAME: string;
  VERSION: string;
  APP_ENV: string;
  API: {
    ROOT_URL: string;
    VERSION: string;
  };
  FEATURES?: {
    CONFIRMATION_OF_PAYEE?: boolean;
    SUBSCRIPTION?: boolean;
  };
}

if (CONFIG.APP_ENV !== "production" || process.env.NODE_ENV !== "production") {
  console.info("App сonfiguration:", CONFIG);
} else {
  console.info(`v${CONFIG.VERSION}`);
}

export default CONFIG as AppConfig;
