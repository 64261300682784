import { Box, BoxProps, Typography } from "@mui/material";

interface Props extends BoxProps {
  title: string;
}

const ScreenHeader = ({ title, ...props }: Props) => (
  <Box mb={2} {...props} data-testid="screen-header-title">
    <Typography variant="h3">{title}</Typography>
  </Box>
);

export default ScreenHeader;
