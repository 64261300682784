import { colors, ThemeOptions, darken } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#f5f6f8",
    paper: colors.common.white,
  },
  primary: {
    main: "#13347D",
  },
  secondary: {
    main: "#DE1927",
    contrastText: "#ffffff",
  },
  error: {
    main: darken("#D91C28", 0.18),
  },
  action: {
    active: "#13347D",
  },
  text: {
    primary: "#262626",
    secondary: colors.blueGrey[600],
  },
};

export default palette;
