import { ReactNode } from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { IMAGES } from "@APP/assets";
import CONFIG from "@APP/config";

interface AuthLayoutProps {
  mainContent: ReactNode;
  introContent?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  introContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
}));

const DefaultIntroContent = (
  <>
    <Box mb={4} display="flex" justifyContent="center">
      <img src={IMAGES.APP_LOGO} width="45%" alt={CONFIG.PRODUCT_NAME} />
    </Box>
    <Typography variant="h1">Admin Portal</Typography>
  </>
);

const AuthLayout = ({ mainContent, introContent = DefaultIntroContent }: AuthLayoutProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Hidden mdDown>
        <Grid className={classes.introContainer} item md={6}>
          {introContent}
        </Grid>
      </Hidden>
      <Grid className={classes.mainContainer} item xs={12} md={6}>
        {mainContent}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
