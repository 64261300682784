import { useEffect, useState } from "react";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import { formatErrorMessage } from "@APP/utils";
import { AppState, hideLoader, setOrganisationSearchResults, showLoader } from "@APP/redux";
import { NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION, commonFormatDate } from "@APP/constants";
import { CommonDateRangeSelectionButtons, Page } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { API } from "@APP/services";
import { User } from "@APP/types";

import OrganisationList from "./OrganisationList";

export interface Organisation {
  orgId: string;
  orgName: string;
  status: string;
  subscriptionStatus: string;
  createdAt?: string;
  users: User[];
  order?: string;
}

export interface OrganisationData {
  data: Organisation[];
  meta?: {
    totalItems: number;
  };
}

const OrganisationDashboardView = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { from, to } = useSelector(
    ({
      userAdmin: {
        organisationDashboard: { from, to },
      },
    }: AppState) => ({
      from,
      to,
    }),
  );

  const [entries, setEntries] = useState(25);
  const [page, setPage] = useState(0);
  const [organisationData, setOrganisationData] = useState<OrganisationData>();
  const [customDatePicker, setCustomDatePicker] = useState(false);
  const [submitFrom, setSubmitFrom] = useState("");
  const [submitTo, setSubmitTo] = useState("");
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState("createdAt");

  const handleErrorCodes = useHandleErrorCodes();

  const handleFetchOrganisations = async ({ from, to }: { from: string; to: string }) => {
    const utcFrom = zonedTimeToUtc(startOfDay(new Date(from)), "UTC").toISOString();
    const utcTo = zonedTimeToUtc(endOfDay(new Date(to)), "UTC").toISOString();

    try {
      let sort_by = "";
      switch (orderBy) {
        case "orgName":
          sort_by = "org_name";
          break;
        case "status":
          sort_by = "status";
          break;
        case "createdAt":
        case "createdTime":
          sort_by = "created_at";
          break;
        case "subscriptionStatus":
          sort_by = "subscription_status";
          break;
        default:
          break;
      }
      dispatch(showLoader());
      const orgData: OrganisationData = await API.getSearchedOrganisations(
        {
          from: utcFrom,
          to: utcTo,
        },
        { page: page, entries: entries },
        `sort_by=${sort_by}&sort_type=${order}`,
      );
      if (orgData?.meta?.totalItems) {
        if (orgData.meta.totalItems <= entries) {
          setLastPage(1);
        } else {
          const lastPageIndex = Math.ceil(orgData.meta.totalItems / entries);
          setLastPage(lastPageIndex);
        }
      }

      setOrganisationData(orgData);
      dispatch(setOrganisationSearchResults(orgData));
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert.open("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    const dateString = new Date().toISOString();
    handleFetchOrganisations({ from: submitFrom || dateString, to: submitTo || dateString });
  }, [entries, page, order, orderBy]);

  const handleOnSubmit = ({ from, to }: { from: string; to: string }) => {
    handleFetchOrganisations({ from, to });
    setSubmitFrom(from);
    setSubmitTo(to);
    setPage(0);
  };

  const { errors, handleSubmit, touched, values, setFieldValue } = useFormik({
    initialValues: {
      from: zonedTimeToUtc(from, "UTC").toISOString(),
      to: zonedTimeToUtc(to, "UTC").toISOString(),
    },
    onSubmit: handleOnSubmit,
  });

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
  };

  useEffect(() => {
    const from = formatISO(startOfDay(new Date()));
    const to = formatISO(new Date());
    const utcFrom = zonedTimeToUtc(from, "UTC").toISOString();
    const utcTo = zonedTimeToUtc(to, "UTC").toISOString();
    handleFetchOrganisations({ from: utcFrom, to: utcTo });
  }, []);

  return (
    <>
      <Page title="User Dashboard">
        <form onSubmit={handleSubmit}>
          <Card elevation={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box p={2}>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} md={8} lg={8}>
                      <CommonDateRangeSelectionButtons
                        fieldValueSetter={setFieldValue}
                        contentJustify="space-evenly"
                        values={values}
                        setCustomDatePicker={setCustomDatePicker}
                      />
                    </Grid>
                    {customDatePicker && (
                      <Grid container item direction="column" xs={12} sm={4} md={4} spacing={1}>
                        <Grid style={{ display: "flex", gap: "8px" }} item>
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                error={Boolean(touched.from && errors.from)}
                                helperText={touched.from && errors.from}
                                fullWidth
                                size="small"
                                onClick={() => setStartDatePickerOpen(true)}
                                {...props}
                              />
                            )}
                            onChange={(value) => {
                              setFieldValue("from", value);
                            }}
                            value={values.from}
                            label="Start Date"
                            inputFormat={commonFormatDate}
                            disableFuture
                            open={startDatePickerOpen}
                            onOpen={() => setStartDatePickerOpen(true)}
                            onClose={() => setStartDatePickerOpen(false)}
                          />
                          <DatePicker
                            renderInput={(props) => (
                              <TextField
                                error={Boolean(touched.to && errors.to)}
                                helperText={touched.to && errors.to}
                                fullWidth
                                size="small"
                                onClick={() => setEndDatePickerOpen(true)}
                                label="End Date"
                                {...props}
                              />
                            )}
                            onChange={(value) => {
                              setFieldValue("to", value);
                            }}
                            value={values.to}
                            label="End Date"
                            inputFormat={commonFormatDate}
                            disableFuture
                            open={endDatePickerOpen}
                            onOpen={() => setEndDatePickerOpen(true)}
                            onClose={() => setEndDatePickerOpen(false)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {customDatePicker && (
                  <>
                    <Box p={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Grid container item style={{ display: "contents" }}>
                        <Grid item lg={4} xs={false} />
                        <Grid item lg={4} xs={12}>
                          <Button color="primary" fullWidth type="submit" variant="contained">
                            View
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Card>
          <OrganisationList
            data={(organisationData as OrganisationData) || []}
            entries={entries}
            setEntries={setEntries}
            page={page}
            lastPage={lastPage}
            setPage={setPage}
            handleOnEntriesChange={handleOnEntriesChange}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
        </form>
      </Page>
    </>
  );
};

export default OrganisationDashboardView;
