import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  backButtonText?: string;
  backButtonDataTestId?: string;
  continueButtonText?: string;
  continueButtonDataTestId?: string;
  handleBackButton?: () => void;
  handleContinue?: () => void;
  disabledContinueButton?: boolean;
  typeButtonContinue?: "button" | "reset" | "submit";
};

const FooterActionsButtons = ({
  backButtonText,
  backButtonDataTestId,
  continueButtonText,
  continueButtonDataTestId,
  handleBackButton,
  handleContinue,
  typeButtonContinue,
  disabledContinueButton = false,
}: Props) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box mb={3} mt={3}>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={7} xs={12}>
          {backButtonText && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              fullWidth={isPhone}
              data-testid={backButtonDataTestId}
              onClick={handleBackButton}>
              <ChevronLeftIcon />
              {backButtonText}
            </Button>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={5} xs={12}>
          <Grid container justifyContent="flex-end" spacing={0}>
            {continueButtonText && (
              <Button
                type={typeButtonContinue}
                fullWidth={isPhone}
                variant="contained"
                size="small"
                color="primary"
                disabled={disabledContinueButton}
                data-testid={continueButtonDataTestId}
                onClick={handleContinue}>
                {continueButtonText}
                <ChevronRightIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterActionsButtons;
