import makeStyles from "@mui/styles/makeStyles";
import {
  CartesianGrid,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { randomColor } from "@APP/utils";

import { ChartProps } from "./ChartContainer";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    ...theme.typography.body2,
  },
}));

const LineChart = ({ data, dataKeys }: ChartProps) => {
  const classes = useStyles();

  return (
    <ResponsiveContainer className={classes.chartContainer}>
      <RechartsLineChart data={data} margin={{ top: 5, right: 5, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} />
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} />
        <Tooltip />
        {dataKeys &&
          dataKeys.map((dataKey, index) => (
            <Line key={index} type="monotone" dataKey={dataKey} stroke={randomColor()} />
          ))}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
