import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Box, CircularProgress, Fade } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LockIcon from "@mui/icons-material/Lock";

import { SCREEN_PATHS } from "@APP/navigation";
import { AppState, logout } from "@APP/redux";
import { timeout } from "@APP/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    height: "100%",
    width: "auto",
  },
  spinner: {
    position: "absolute",
    left: -20,
    top: -20,
  },
}));

interface Props {
  auth: AppState["auth"];
  logout: ReturnType<typeof mapDispatchToProps>["logout"];
}

export const LogoutView = ({ auth, logout }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (auth.authenticated) {
      logout();
      timeout(1500).then(() => history.replace(SCREEN_PATHS.LOGIN));
    } else {
      history.replace(SCREEN_PATHS.LOGIN);
    }
  }, []);

  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <Fade in timeout={{ enter: 500 }}>
        <Box
          display="flex"
          height={60}
          position="relative"
          justifyContent="center"
          alignItems="center"
          mt={-6}>
          <LockIcon className={classes.icon} color="primary" />
          <CircularProgress className={classes.spinner} color="primary" size={100} />
        </Box>
      </Fade>
    </Box>
  );
};

const mapStateToProps = ({ auth }: AppState) => ({ auth });

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
