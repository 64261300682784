import { ComponentType, useEffect, useMemo, useState } from "react";
import { Card, CardContent, CardHeader, Divider, Grid, GridSize, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles/withStyles";
import { format } from "date-fns";

import { CHART_FILTER_FIELDS, REPORT_NAMES, commonFormatDate } from "@APP/constants";
import { ChartData, LineData, Report } from "@APP/types";
import { generateDataKeys } from "@APP/utils";

import { ChartHeaderButtonProps } from "./components/chartHeaderButtons";

export type ReportDataFormatter = (
  reportName: keyof typeof REPORT_NAMES,
  report: Report,
) => ChartData[] | LineData[];

type CommonChartProps = {
  dataFormatter: ReportDataFormatter;
};

type ChartContainerProps = CommonChartProps & {
  components: ChartComponent[];
  visualisations: string[];
};

export type ChartProps = {
  data: ChartData[] | LineData[];
  percent?: boolean;
  tooltipProps?: {
    valueTitle?: string;
  };
  dataKeys?: string[];
};

export type ChartComponent = Partial<ChartProps> & {
  reportName: keyof typeof REPORT_NAMES;
  label: string;
  component: ComponentType<ChartProps>;
  report: Report;
  chartFilters: CHART_FILTER_FIELDS[];
  headerButton: ComponentType<ChartHeaderButtonProps>;
  size?: GridSize;
  dataKeys?: string[];
  lineChart?: boolean;
};

type Props = ChartComponent &
  CommonChartProps & {
    tooltipFormatter?: () => string;
    classes: ClassNameMap;
  };

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    minHeight: 220,
  },
  cardContent: {
    display: "flex",
  },
}));

const Chart = ({
  component: Component,
  reportName,
  percent,
  label,
  headerButton: HeaderActionButton,
  classes,
  tooltipProps,
  dataFormatter,
  report,
  lineChart,
  chartFilters,
}: Props) => {
  const [formattedData, setFormattedData] = useState<ChartData[] | LineData[]>(
    dataFormatter(reportName, report),
  );

  const { data } = report;

  const {
    selectedDates,
    selectedPartners,
    selectedStatus,
    selectedChannels,
    selectedBanks,
    selectedErps,
  } = report.filters;

  useEffect(() => {
    setFormattedData(dataFormatter(reportName, report));
  }, [
    data,
    selectedDates,
    selectedPartners,
    selectedStatus,
    selectedChannels,
    selectedBanks,
    selectedErps,
  ]);

  const dataKeys = useMemo(() => generateDataKeys(reportName, data), [data]);

  const subHeader =
    report.from &&
    report.to &&
    `From ${format(new Date(report.from), commonFormatDate)} - to ${format(
      new Date(report.to),
      commonFormatDate,
    )}`;

  const NoData = () => {
    return (
      <CardContent className={classes.cardContent}>
        <Typography>No data matches the given time period / filter.</Typography>
      </CardContent>
    );
  };

  return (
    <Card>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <CardHeader
            title={label}
            titleTypographyProps={{ variant: "h6" }}
            subheader={subHeader}
            subheaderTypographyProps={{ variant: "caption" }}
            action={
              <HeaderActionButton
                report={report}
                reportName={reportName}
                chartFilters={chartFilters}
              />
            }
          />
          <Divider />
          <CardContent className={classes.cardContent}>
            <Grid item xs={12} className={classes.cardGrid}>
              {formattedData && !!formattedData.length && Component ? (
                <Component
                  data={lineChart ? (formattedData as LineData[]) : (formattedData as ChartData[])}
                  percent={percent}
                  tooltipProps={tooltipProps}
                  dataKeys={dataKeys}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

const ChartContainer = ({ components, visualisations, dataFormatter }: ChartContainerProps) => {
  const classes = useStyles();

  // visualisations sorted by order of dashboard components array
  const sortedVisualisations = useMemo(
    () =>
      components
        .filter((component) => visualisations.includes(component.reportName))
        .map((component) => component.reportName),
    [components, visualisations],
  );

  const charts = sortedVisualisations.map((name) => {
    const component = components.find((component) => name === component.reportName);

    return (
      component && (
        <Grid item xs={12} md={component.size ? component.size : 6} key={name}>
          <Chart {...component} classes={classes} dataFormatter={dataFormatter} />
        </Grid>
      )
    );
  });

  return <>{charts}</>;
};
export default ChartContainer;
