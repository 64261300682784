import { createActionCreator } from "deox";

export const showLoader = createActionCreator(
  "@dashboardApp/SHOW_LOADER",
  (resolve) => () => resolve(),
);

export const hideLoader = createActionCreator(
  "@dashboardApp/HIDE_LOADER",
  (resolve) => () => resolve(),
);
