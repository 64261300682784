import axios, { AxiosRequestConfig } from "axios";

import AppLocalStorage from "../AppLocalStorage";

import { HEADERS } from "./constants";

type RequestOptions = {
  auth?: boolean;
};

const REQUEST_DEFAULT_OPTIONS: RequestOptions = {
  auth: true,
};

/**
 * HTTP client wrapper around "axios" API.
 * @param config "axios" config: https://github.com/axios/axios#request-config
 * @param options
 *  "auth" - include auth header in the request;
 */
const request = async <T = any>(config: AxiosRequestConfig, options = REQUEST_DEFAULT_OPTIONS) => {
  options = { ...REQUEST_DEFAULT_OPTIONS, ...options };
  let headers = config.headers || {};

  // Attach auth token header based on options:
  if (options.auth) {
    const bankifiToken = AppLocalStorage.getItem("auth.token");
    if (bankifiToken) headers = { ...headers, [HEADERS.AUTH_HEADER]: bankifiToken };
  }

  return axios.request<T>({ ...config, headers });
};

export default request;
