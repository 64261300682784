import { ChangeEvent, Fragment, MouseEvent, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Card,
  Collapse,
  Grid,
  IconButton,
  Link,
  Popover,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";
import { format, parseISO } from "date-fns";

import { CommonTextField, ScreenHeader, SortIcon, Table } from "@APP/components";
import { tableStyles as useStyles } from "@APP/styles";
import { formatCurrency, formatDisplayedDate } from "@APP/utils";
import { RTP_STATUS_LABEL } from "@APP/constants";
import { RtpSortBy, SortType } from "@APP/types";

import { DetailPanel } from "./DetailPanel";
import { RTPWithDisplaying } from "./RTPDashboardView";

type Props = {
  data: RTPWithDisplaying[];
  rtpSort: RtpSortBy;
  sortType: SortType;
  entries: number;
  page: number;
  lastPage: number;
  setEntries: (entries: number) => void;
  setPage: (page: number) => void;
  clearSearchFilter: () => void;
  searchData: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSorting: (id: RtpSortBy) => void;
  handleOnEntriesChange: (entries: number) => void;
};

interface HeadCell {
  id: RtpSortBy;
  rightAlign: boolean;
  disablePadding: boolean;
  label: string;
}

const HEADER_CELL_ITEMS: HeadCell[] = [
  { id: RtpSortBy.email, rightAlign: false, disablePadding: false, label: "Username" },
  { id: RtpSortBy.createdAt, rightAlign: false, disablePadding: false, label: "Created Date" },
  { id: RtpSortBy.createdAtTime, rightAlign: false, disablePadding: false, label: "Created Time" },
  {
    id: RtpSortBy.firstDeliveryDate,
    rightAlign: false,
    disablePadding: false,
    label: "First Delivery Date",
  },
  {
    id: RtpSortBy.firstDeliveryTime,
    rightAlign: false,
    disablePadding: false,
    label: "First Delivery Time",
  },
  { id: RtpSortBy.amount, rightAlign: true, disablePadding: false, label: "Amount" },
  { id: RtpSortBy.dueDateTime, rightAlign: false, disablePadding: false, label: "Due Date" },
  { id: RtpSortBy.status, rightAlign: false, disablePadding: false, label: "Status" },
  {
    id: RtpSortBy.channel,
    rightAlign: false,
    disablePadding: false,
    label: "Delivery Method",
  },
];

// The +2 accounts for the leading and trailing action
const EMPTY_ROW_COL_SPAN = HEADER_CELL_ITEMS.length + 2;

const RTPDashboardList = ({
  data,
  rtpSort,
  sortType,
  entries,
  page,
  lastPage,
  setPage,
  clearSearchFilter,
  searchData,
  handleSorting,
  handleOnEntriesChange,
}: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statusEl, setStatusEl] = useState<HTMLDivElement | null>(null);
  const [statusPopId, setStatusPopId] = useState<string | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const statusPopoverOpen = Boolean(statusEl);

  const handlePopoverClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    clearSearchFilter();
  };

  const handleStatusPopoverClick = (event: MouseEvent<HTMLDivElement>, id: string) => {
    setStatusEl(event.currentTarget);
    setStatusPopId(id);
  };

  const handleStatusPopoverClose = () => {
    setStatusEl(null);
    setStatusPopId(null);
  };

  const renderHeader = () => (
    <TableRow className={classes.tableHead}>
      <TableCell padding="checkbox"></TableCell>
      {HEADER_CELL_ITEMS.map(({ id, label, disablePadding }) => (
        <TableCell
          key={id}
          align="left"
          padding={disablePadding ? "none" : "normal"}
          onClick={() => handleSorting(id)}>
          <Box display="flex" alignItems="center" data-testid={`${label}-field-label`}>
            {label}
            {!!data.length && <SortIcon active={rtpSort === id} type={sortType} />}
          </Box>
        </TableCell>
      ))}
      <TableCell padding="checkbox">
        <Tooltip title="Filter list" placement="right">
          <IconButton aria-label="filter list" onClick={handlePopoverClick} size="large">
            <FilterListIcon id="filter-list-icon" />
          </IconButton>
        </Tooltip>
        <Popover
          anchorEl={anchorEl}
          open={popoverOpen}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <CommonTextField
            fullWidth
            placeholder="Filter on email, amount.."
            variant="outlined"
            onChange={searchData}
          />
        </Popover>
      </TableCell>
    </TableRow>
  );

  const renderRows = ({
    amount: { amount: rtpAmount, currency },
    id,
    receivable: { dueDateTime },
    status,
    supplier: {
      companyInfo: { email },
    },
    customer: { name: payer, email: payerEmail },
    createdAt,
    rtpCreationUserZone,
    deliveryDates,
    channel,
    history,
  }: RTPWithDisplaying) => {
    return (
      <Fragment key={id}>
        <TableRow className={classes.tableRow}>
          <TableCell
            padding="checkbox"
            onClick={() => setExpanded(expanded.includes(id) ? [] : [id])}>
            <IconButton aria-label="expand row" size="small">
              {expanded.includes(id) ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{createdAt ? formatDisplayedDate(createdAt) : "-"}</TableCell>
          <TableCell>
            {createdAt ? formatInTimeZone(createdAt, rtpCreationUserZone ?? "UTC", "HH:mm") : "-"}
            {/* to handle the small subset of RTP's that have a createdAt date before the userZone was added */}
            {!rtpCreationUserZone && " (UTC)"}
          </TableCell>
          <TableCell>{deliveryDates ? formatDisplayedDate(deliveryDates[0]) : "-"}</TableCell>
          <TableCell>
            {deliveryDates ? formatDisplayedDate(deliveryDates[0], "HH:mm") : "-"}
          </TableCell>
          <TableCell>{formatCurrency(rtpAmount, currency)}</TableCell>
          <TableCell>{formatDisplayedDate(dueDateTime)}</TableCell>
          <TableCell>
            <div style={{ cursor: "pointer" }} onClick={(e) => handleStatusPopoverClick(e, id)}>
              <Link>{RTP_STATUS_LABEL[status]}</Link>
            </div>
            <Popover
              anchorEl={statusEl}
              open={statusPopoverOpen && id === statusPopId}
              onClose={handleStatusPopoverClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                p="24px"
                spacing={1}
                maxWidth="325px">
                <Grid item xs={5}>
                  <Typography variant="h6">Status</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h6">Date/Time</Typography>
                </Grid>
                {history?.map((record) => (
                  <Fragment key={record.timestamp}>
                    <Grid item xs={5}>
                      <Typography variant="caption">{RTP_STATUS_LABEL[record.status]}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="caption">
                        {format(parseISO(record.timestamp), "yyyy-MM-dd HH:mm:ss")}
                      </Typography>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Popover>
          </TableCell>
          <TableCell>{channel}</TableCell>
          <TableCell padding="checkbox" />
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: "none" }} colSpan={8}>
            <Collapse in={expanded.includes(id)} timeout="auto" unmountOnExit>
              <DetailPanel email={payerEmail} payer={payer} />
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={EMPTY_ROW_COL_SPAN} align="center">
        No Matching RTPS found.
      </TableCell>
    </TableRow>
  );

  return (
    <Box my={4}>
      <ScreenHeader title="RTP's" />
      <Card elevation={12}>
        <Table
          data={data.filter((rtp) => !rtp.hidden).slice(page * entries, (page + 1) * entries)}
          renderHeader={renderHeader}
          renderRows={renderRows}
          showPagination={true}
          onEntriesChange={handleOnEntriesChange}
          onPageChange={setPage}
          entries={entries}
          page={page}
          lastPage={lastPage}
          renderEmptyDataRow={renderEmptyDataRow}
        />
      </Card>
    </Box>
  );
};

export default RTPDashboardList;
