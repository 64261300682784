import { List, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import StopIcon from "@mui/icons-material/Stop";

import { ChartData } from "@APP/types";

import { ChartProps } from "../ChartContainer";

const useStyles = makeStyles((theme) => ({
  legendIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  listContainer: {
    maxHeight: "200px",
    alignItems: "center",
    overflow: "auto",
    padding: theme.spacing(1),
  },
}));

const ChartLegend = ({ data, percent }: ChartProps) => {
  const classes = useStyles();
  const dataAsChartData = [...(data as ChartData[])];

  return (
    <List className={classes.listContainer}>
      {dataAsChartData.map(({ name, color, value }: ChartData, index: number) => (
        <li key={`legend-item-${index}`} className={`${classes.legendIcon} legend-item-${index}`}>
          <StopIcon style={{ color: color }} />
          <span>
            <Typography
              key={`cell-${index}`}
              variant="caption"
              style={{ color: color }}>{` ${name} - ${value}${percent ? "%" : ""}`}</Typography>
          </span>
        </li>
      ))}
    </List>
  );
};

export default ChartLegend;
