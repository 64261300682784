import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, formatISO, isAfter, startOfDay } from "date-fns";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { ListIterator, Many, NotVoid, get, orderBy } from "lodash";
import queryString from "query-string";
import * as Yup from "yup";
import { formatInTimeZone } from "date-fns-tz";
import makeStyles from "@mui/styles/makeStyles";

import { CommonDateRangeSelectionButtons, Page } from "@APP/components";
import { NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION, commonFormatDate } from "@APP/constants";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { AppState, hideLoader, setRTPS, setRtpFrom, setRtpTo, showLoader } from "@APP/redux";
import { API } from "@APP/services";
import { AdminRTPFilter, RTP, RtpSortBy, SortType } from "@APP/types";
import { formatErrorMessage } from "@APP/utils";

import RTPDashboardList from "./RTPDashboardList";

export type RTPWithDisplaying = RTP & { hidden: boolean };

enum RtpSortPaths {
  dueDateTime = "receivable.dueDateTime",
  createdAt = "createdAt",
  createdAtTime = "createdAt",
  amount = "amount.amount",
  status = "status",
  email = "supplier.primaryContact.email",
  firstDeliveryDate = "deliveryDates",
  firstDeliveryTime = "deliveryDates",
  channel = "channel",
}

export const adminRTPValidationSchema = Yup.object().shape({
  from: Yup.date().required("Start date is required"),
  to: Yup.date().test("to", "End date must be after start date", (value, context) => {
    if (!value) return false;
    // start date can be the same as end date
    if (value.getTime() === context.parent.from.getTime()) return true;
    if (!context.parent.from) return false;

    return isAfter(value, context.parent.from);
  }),
});

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(0.5),
  },
}));

const RTPDashboardView = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleErrorCodes = useHandleErrorCodes();
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [customDatePicker, setCustomDatePicker] = useState(false);
  const [entries, setEntries] = useState(25);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [rtpSort, setRtpSort] = useState<RtpSortBy>(RtpSortBy.createdAt);
  const [sortedRtps, setSortedRtps] = useState<RTPWithDisplaying[]>([]);
  const [sortType, setSortType] = useState<SortType>(SortType.asc);
  const { data, from, to } = useSelector(
    ({
      userAdmin: {
        rtpDashboard: { from, to, data },
      },
    }: AppState) => ({
      from,
      to,
      data,
    }),
  );

  const appendHiddenToRtp = (rtps: RTP[]): RTPWithDisplaying[] =>
    rtps.map((rtp) => ({ ...rtp, hidden: false }));

  useEffect(() => {
    !!data.length && setSortedRtps(appendHiddenToRtp(data));
  }, []);

  const getRtpsInDateRangeRecursively = async (
    filter: AdminRTPFilter,
    page = 0,
    prevRtps: RTP[] = [],
  ): Promise<RTP[]> => {
    const { data: rtpData, links, meta } = await API.getRTPSinDateRange(filter, { page });
    const rtps = [...prevRtps, ...rtpData] as RTP[];

    if (links.next) {
      return getRtpsInDateRangeRecursively(filter, page + 1, rtps);
    }
    const { query } = queryString.parseUrl(links?.last ?? "");
    setTotalItems(meta.totalItems);
    if (meta.totalItems) {
      if (meta.totalItems <= entries) {
        setLastPage(1);
      } else {
        if (query.page) {
          const lastPageIndex = Math.ceil(meta.totalItems / entries);
          setLastPage(Math.floor(lastPageIndex));
        }
      }
    }

    return rtps;
  };

  const fetchRTPs = async ({ from, to }: { from: string; to: string }) => {
    try {
      dispatch(showLoader());
      const rtpsData = await getRtpsInDateRangeRecursively({ from, to });

      dispatch(setRTPS(rtpsData));
      setSortedRtps(appendHiddenToRtp(rtpsData));
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (!isHandled) return;
      alert.open("Error", formatErrorMessage(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const sortingHelper = (
    sortedRtps: RTPWithDisplaying[],
    by: RtpSortBy,
    orderByCondition: Many<ListIterator<any, NotVoid>> = (rtp: RTPWithDisplaying) =>
      get(rtp, rtpSortField),
    sortType: SortType,
  ) => {
    const rtpSortField = RtpSortPaths[by];
    const rtpsWithoutSortingField = sortedRtps.filter((rtp) => !get(rtp, rtpSortField));
    const rtpsWithSortingField = sortedRtps.filter((rtp) => get(rtp, rtpSortField));

    const sortedRtpsWithSortingField = orderBy(rtpsWithSortingField, orderByCondition, sortType);

    setSortedRtps([...sortedRtpsWithSortingField, ...rtpsWithoutSortingField]);
  };

  const handleSorting = (by: RtpSortBy) => {
    const newSort = by === rtpSort && sortType === SortType.asc ? SortType.desc : SortType.asc;
    if (by === rtpSort) {
      setSortedRtps(sortedRtps.reverse());

      return;
    } else {
      switch (by) {
        case RtpSortBy.amount: {
          setSortedRtps(
            orderBy(sortedRtps, (rtp) => parseFloat(get(rtp, RtpSortPaths[by])), sortType),
          );
          break;
        }
        case RtpSortBy.createdAtTime || RtpSortBy.createdAt: {
          // older rtps don't have createdAt field. So we need to sort them separately
          const rtpsWithoutCreatedAt = sortedRtps.filter((rtp) => !rtp.createdAt);
          const rtpsWithCreatedAt = sortedRtps.filter((rtp) => rtp.createdAt);

          if (by === RtpSortBy.createdAtTime) {
            const sortedRtpsWithCreatedAtToLocalTime = orderBy(
              rtpsWithCreatedAt,
              (rtp) =>
                formatInTimeZone(
                  rtp.createdAt as string | number,
                  rtp.rtpCreationUserZone ?? "UTC",
                  "HH:mm:ss",
                ),
              newSort,
            );
            setSortedRtps([...sortedRtpsWithCreatedAtToLocalTime, ...rtpsWithoutCreatedAt]);
          } else {
            const sortedRtpsWithCreatedAtToLocalTime = orderBy(
              rtpsWithCreatedAt,
              (rtp) => format(new Date(rtp.createdAt as string | number), "dd-MM-yyyy HH:mm:ss"),
              newSort,
            );
            setSortedRtps([...sortedRtpsWithCreatedAtToLocalTime, ...rtpsWithoutCreatedAt]);
          }
          break;
        }

        // has to have it's own cases as rtp.deliveryDate is an array of dates.
        case RtpSortBy.firstDeliveryDate: {
          const dateSort = (rtp: RTPWithDisplaying) =>
            format(new Date(get(rtp, RtpSortPaths[by] as string | number)[0]), commonFormatDate);
          const timeSort = (rtp: RTPWithDisplaying) =>
            format(new Date(get(rtp, RtpSortPaths[by] as string | number)[0]), "HH:mm");

          sortingHelper(sortedRtps, by, [dateSort, timeSort], newSort);
          break;
        }
        case RtpSortBy.firstDeliveryTime: {
          const rtpsWithoutDeliveryDate = sortedRtps.filter((rtp) => !rtp.deliveryDates);
          const rtpsWithDeliveryDate = sortedRtps.filter(
            (rtp) => rtp.deliveryDates && rtp.deliveryDates.length > 0,
          );

          const sortedRtpsWithDeliveryDate = orderBy(
            rtpsWithDeliveryDate,
            (rtp) => format(new Date(rtp.deliveryDates![0]), "HH:mm"),
            newSort,
          );

          setSortedRtps([...sortedRtpsWithDeliveryDate, ...rtpsWithoutDeliveryDate]);
          break;
        }
        default: {
          setSortedRtps(orderBy(sortedRtps, RtpSortPaths[by], newSort));
        }
      }
      setRtpSort(by);
      setSortType(newSort);
    }
  };

  const searchData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toLowerCase();
      if (!value) {
        setSortedRtps(sortedRtps.map((rtp) => ({ ...rtp, hidden: false })));

        return;
      }

      const filteredRtps = sortedRtps.map((rtp) => {
        const {
          amount: { amount },
          createdAt,
          status,
          supplier: {
            primaryContact: { email },
          },
        } = rtp;

        const filterOn = [createdAt, amount, status, email];
        if (filterOn.some((filter) => filter?.toString().toLowerCase().includes(value))) {
          return { ...rtp, hidden: false };
        }

        return { ...rtp, hidden: true };
      });

      setSortedRtps(filteredRtps);
    },
    [sortedRtps],
  );

  const handleOnEntriesChange = (entries: number) => {
    setPage(NUMBER_OF_FIRST_PAGE_IN_TABLE_WITH_PAGINATION);
    setEntries(entries);
    setLastPage(Math.ceil(totalItems / entries));
  };

  const clearSearchFilter = () =>
    setSortedRtps(sortedRtps.map((rtp) => ({ ...rtp, hidden: false })));

  const handleOnSubmit = ({ from, to }: { from: string; to: string }) => {
    dispatch(setRtpFrom(from));
    dispatch(setRtpTo(to));
    fetchRTPs({ from, to });
    setSubmitting(false);
    setPage(0);
  };

  const { errors, handleSubmit, isSubmitting, touched, values, setFieldValue, setSubmitting } =
    useFormik({
      initialValues: {
        from: from,
        to: to,
      },
      validationSchema: adminRTPValidationSchema,
      onSubmit: handleOnSubmit,
    });

  useEffect(() => {
    const from = formatISO(startOfDay(new Date()));
    const to = formatISO(new Date());
    fetchRTPs({ from, to });
  }, []);

  return (
    <Page title="RTP Dashboard">
      <form onSubmit={handleSubmit}>
        <Card elevation={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box p={2}>
                <Grid container item spacing={1}>
                  <Grid className={classes.buttonsContainer} item xs={12} md={8} lg={8}>
                    <CommonDateRangeSelectionButtons
                      fieldValueSetter={setFieldValue}
                      contentJustify="space-evenly"
                      values={values}
                      setCustomDatePicker={setCustomDatePicker}
                    />
                  </Grid>
                  {customDatePicker && (
                    <Grid container item direction="column" xs={12} sm={4} md={4} spacing={1}>
                      <Grid style={{ display: "flex", gap: "8px" }} item>
                        <DatePicker
                          renderInput={(props) => (
                            <TextField
                              error={Boolean(touched.from && errors.from)}
                              helperText={touched.from && errors.from}
                              fullWidth
                              size="small"
                              onClick={() => setStartDatePickerOpen(true)}
                              {...props}
                            />
                          )}
                          onChange={(value) => {
                            setFieldValue("from", value);
                          }}
                          value={values.from}
                          label="Start Date"
                          inputFormat={commonFormatDate}
                          disableFuture
                          open={startDatePickerOpen}
                          onOpen={() => setStartDatePickerOpen(true)}
                          onClose={() => setStartDatePickerOpen(false)}
                        />
                        {/* </Grid>
                      <Grid item> */}
                        <DatePicker
                          renderInput={(props) => (
                            <TextField
                              error={Boolean(touched.to && errors.to)}
                              helperText={touched.to && errors.to}
                              fullWidth
                              size="small"
                              onClick={() => setEndDatePickerOpen(true)}
                              label="End Date"
                              {...props}
                            />
                          )}
                          onChange={(value) => {
                            setFieldValue("to", value);
                          }}
                          value={values.to}
                          label="End Date"
                          inputFormat={commonFormatDate}
                          disableFuture
                          open={endDatePickerOpen}
                          onOpen={() => setEndDatePickerOpen(true)}
                          onClose={() => setEndDatePickerOpen(false)}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {customDatePicker && (
                <>
                  <Box p={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Grid container item style={{ display: "contents" }}>
                      <Grid item lg={4} xs={false} />
                      <Grid item lg={4} xs={12}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          type="submit"
                          variant="contained">
                          View
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Card>
        <RTPDashboardList
          data={sortedRtps}
          rtpSort={rtpSort}
          sortType={sortType}
          entries={entries}
          page={page}
          lastPage={lastPage}
          setEntries={setEntries}
          setPage={setPage}
          clearSearchFilter={clearSearchFilter}
          searchData={searchData}
          handleSorting={handleSorting}
          handleOnEntriesChange={handleOnEntriesChange}
        />
      </form>
    </Page>
  );
};

export default RTPDashboardView;
