import { createReducer } from "deox";

import { hideLoader, showLoader } from "@APP/redux/actions/dashboardApp";

export interface DashboardAppState {
  isLoading: boolean;
}

const defaultState: DashboardAppState = {
  isLoading: false,
};

const dashboardAppReducer = createReducer(defaultState, (handleAction) => [
  handleAction(showLoader, (state) => ({ ...state, isLoading: true })),
  handleAction(hideLoader, (state) => ({ ...state, isLoading: false })),
]);

export default dashboardAppReducer;
