import CONFIG from "@APP/config";
import {
  RequestPagination,
  ResponsePagination,
  OrganisationSearchTerm,
  OrganisationData,
  SubscriptionPlan,
  SubscriptionDetails,
} from "@APP/types";

import request from "../request";

export const getSearchedOrganisations = async (
  searchTerms: OrganisationSearchTerm,
  pagination: RequestPagination = { page: 0, entries: 25 },
  queryParams?: string,
) => {
  const { page = 0, entries = 25 } = pagination;
  const response = await request<{
    data: OrganisationData[];
    links: ResponsePagination;
    meta: { totalItems: number };
  }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/organisations/search?${queryParams}`,
    params: {
      page: `${page * entries};;;${entries}`,
      ...searchTerms,
    },
  });

  return response.data;
};

export const changeOrganisationBlockStatus = async (orgId: string, blocked: boolean) => {
  const response = await request({
    method: blocked ? "DELETE" : "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/organisation/blocked`,
    data: { orgId: orgId },
  });

  return response;
};

export const getOrganizationById = async (orgId: string) => {
  const response = await request<OrganisationData>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/admin/organisation/${orgId}`,
  });

  return response.data;
};

export const changeOrganisationSubscriptionStatus = async (
  shouldActivateSubscription: boolean,
  orgId: string,
  id: string,
) => {
  const response = await request({
    method: shouldActivateSubscription ? "POST" : "DELETE",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/subscriptions/${orgId}/${id}`,
  });

  return response;
};

export const getSubscriptionsBillingPlans = async (id: string) => {
  const response = await request<{ data: SubscriptionPlan[] }>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/subscriptions/plans/${id}`,
  });

  return response.data.data;
};

export const getSubscriptionsByOrgId = async (id: string) => {
  const response = await request<SubscriptionDetails>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/admin/subscriptions/${id}`,
  });

  return response.data;
};
