import { Box, Container, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(-4),
  },
}));

const AppLoader = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      textAlign="center">
      <Container className={classes.container} maxWidth="sm">
        <LinearProgress />
      </Container>
    </Box>
  );
};

export default AppLoader;
