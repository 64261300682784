import { CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH, TOP_BAR_HEIGHT } from "@APP/styles";

type Props = {
  isFullSideBar: boolean;
};

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    backgroundColor: "rgb(31, 31, 31)",
    opacity: 0.6,
    width: "100%",
    height: "100%",
    animationDuration: "0.1s",
    animationName: "sliding",
    position: "fixed",
    top: 0,
    zIndex: 20000,
    pointerEvents: "all",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    color: theme.palette.action.active,
  },
  spinnerWithFullSidebar: {
    [theme.breakpoints.up("sm")]: {
      margin: `${TOP_BAR_HEIGHT}px 0 0 ${OPEN_SIDEBAR_WIDTH}px`,
    },
  },
  spinnerWithSmallSidebar: {
    [theme.breakpoints.up("sm")]: {
      margin: `${TOP_BAR_HEIGHT}px 0 0 ${CLOSE_SIDEBAR_WIDTH}px`,
    },
  },
}));

const Loader = ({ isFullSideBar }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress
        className={clsx(classes.spinner, {
          [classes.spinnerWithFullSidebar]: isFullSideBar,
          [classes.spinnerWithSmallSidebar]: !isFullSideBar,
        })}
        size={80}
      />
    </div>
  );
};

export default Loader;
