import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";

/**
 * Common text field Component, open to extend generic properties and not duplicate them
 */

const CommonTextField = (props: TextFieldProps): JSX.Element => (
  <TextField
    type={props.type || "text"}
    variant={props.variant || "outlined"}
    data-testid={props.error ? (props.helperText as string).replace(/ /g, "-").toLowerCase() : null}
    {...props}
  />
);

export default CommonTextField;
