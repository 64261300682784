import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createActionCreator } from "deox";

import { AppState } from "@APP/redux";
import { AuthState } from "@APP/redux/reducers/auth";
import { API, AppLocalStorage } from "@APP/services";

export const setAuthLoading = createActionCreator(
  "@auth/SET_LOADING",
  (resolve) => (isLoading: boolean) => resolve(isLoading),
);
export const setAuthenticated = createActionCreator(
  "@auth/SET_AUTHORISED",
  (resolve) => (isAuthenticated: boolean) => resolve(isAuthenticated),
);
export const setUserData = createActionCreator(
  "@auth/SET_USER_DATA",
  (resolve) => (userData: AuthState["user"]) => resolve(userData),
);
export const login =
  (email: string, password: string) => async (dispatch: ThunkDispatch<AppState, void, Action>) => {
    dispatch({ type: "@auth/LOGIN" });
    dispatch(setAuthLoading(true));
    try {
      const { token } = await API.login(email, password);
      AppLocalStorage.setItem("auth.token", token);
      dispatch(setAuthenticated(true));
      dispatch(setAuthLoading(false));
    } catch (err) {
      dispatch(setAuthLoading(false));
      throw err;
    }
  };

export const logout = createActionCreator("@auth/LOGOUT", (resolve) => () => {
  AppLocalStorage.removeItem("auth.token");
  AppLocalStorage.removeItem("persist:root");

  return resolve();
});
