import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Collapse,
  Link,
  TableCell,
  TableRow,
  Typography,
  Table,
  TableHead,
  TableBody,
  IconButton,
  Switch,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatInTimeZone } from "date-fns-tz";

import { tableStyles as useStyles } from "@APP/styles";
import { User } from "@APP/types";
import { hideLoader, showLoader } from "@APP/redux";
import { API } from "@APP/services";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { commonFormatDate } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { getSubscriptionStatus } from "@APP/utils";

import { Organisation } from "./OrganisationDashboardView";

function OrganisationRows({
  orgId,
  orgName,
  status,
  subscriptionStatus,
  createdAt,
  users,
  order,
}: Organisation) {
  const classes = useStyles();
  const handleErrorCodes = useHandleErrorCodes();
  const alert = useAlert();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(status === "Active" ? true : false);

  const handleBlockOrganisation = async (blocked: boolean) => {
    try {
      dispatch(showLoader());
      await API.changeOrganisationBlockStatus(orgId, blocked);
    } catch (error: any) {
      setChecked(!blocked);
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      if (isHandled) return;
      alert.open("Failure", "We were unable to delete the bank account details, try again later");
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    setChecked(status === "Active");
  }, [status, order, orgId]);

  return (
    <Fragment key={orgId}>
      <TableRow key={orgId} className={classes.tableRow}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link href={`${SCREEN_PATHS.ORG_DETAILS}/${orgId}`}>{orgName}</Link>
        </TableCell>
        <TableCell>{checked ? "Active" : "Blocked"}</TableCell>
        <TableCell>{getSubscriptionStatus(subscriptionStatus || "-")}</TableCell>
        <TableCell>
          {createdAt ? formatInTimeZone(createdAt, "UTC", commonFormatDate) : "-"}
        </TableCell>
        <TableCell>{createdAt ? formatInTimeZone(createdAt, "UTC", "HH:mm") : "-"}</TableCell>
        <TableCell>
          <Switch
            onChange={() => handleBlockOrganisation(!checked)}
            checked={checked}
            onClick={() => setChecked(!checked)}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          component="th"
          scope="row"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Users
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Name
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Created Time</TableCell>
                    <TableCell>Last Logged In</TableCell>
                    <TableCell>Roles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user: User) => (
                    <TableRow key={user.username}>
                      <TableCell component="th" scope="row">
                        {user.username}
                      </TableCell>
                      <TableCell>{user.status}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>
                        {user.meta?.createdAt
                          ? formatInTimeZone(user.meta?.createdAt, "UTC", commonFormatDate)
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {user.meta?.createdAt
                          ? formatInTimeZone(user.meta?.createdAt, "UTC", "HH:mm")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {user.meta?.lastLoginTime
                          ? formatInTimeZone(user.meta?.lastLoginTime, "UTC", commonFormatDate)
                          : "-"}
                      </TableCell>
                      <TableCell>{user.roles.length > 0 ? "Administrator" : "User"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default OrganisationRows;
