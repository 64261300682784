import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import setDefaultOptions from "date-fns/setDefaultOptions"; // eslint-disable-line import/no-duplicates
import enGb from "date-fns/locale/en-GB"; // eslint-disable-line import/no-duplicates
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import store, { persistor } from "@APP/redux";
import { history } from "@APP/navigation";
import { AlertProvider } from "@APP/hooks/useAlert";
import { themeOptions, GlobalStyles } from "@APP/styles";

import RootComponent from "./RootComponent";

setDefaultOptions({ weekStartsOn: 1, locale: enGb });

export function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createTheme({ ...themeOptions })}>
              <GlobalStyles />
              <Router history={history}>
                <AlertProvider>
                  <RootComponent />
                </AlertProvider>
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
