import { Box, Button, Dialog } from "@mui/material";

import { AppModalRouteComponentProps } from "@APP/navigation";

export const PlaceholderModalView = ({ closeAppRouteModal }: AppModalRouteComponentProps) => (
  <Dialog open fullWidth maxWidth="sm" onClose={closeAppRouteModal}>
    <Box>
      <Button onClick={closeAppRouteModal} color="primary">
        Close
      </Button>
    </Box>
  </Dialog>
);

export default PlaceholderModalView;
