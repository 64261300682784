import { combineReducers } from "redux";

import authReducer from "./auth";
import dashboardAppReducer from "./dashboardApp";
import adminReducer from "./userAdmin";
import organisationReducer from "./organisationAdmin";

const appReducer = combineReducers({
  auth: authReducer,
  dashboardApp: dashboardAppReducer,
  userAdmin: adminReducer,
  organisationAdmin: organisationReducer,
});

const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: Parameters<typeof appReducer>["1"],
) => {
  if (action.type === "@auth/LOGOUT") return appReducer(undefined, action);

  return appReducer(state, action);
};

export { rootReducer, authReducer, adminReducer, organisationReducer };

export default rootReducer;
