export const HEADERS = {
  AUTH_HEADER: "x-bankifi-iam-header",
};

export default {
  HEADERS,
};

export enum REPORT_ENDPOINTS {
  peakTraffic = "rtp/peak-traffic",
  declined = "rtp/declined",
  transactions = "rtp/transactions",
  channels = "rtp/channels",
  usersDeactive = "users/deactive",
  usersActive = "users/active",
  usersRegisteredByBank = "users/registered",
  usersRegisteredByErp = "users/registered-erp",
}
