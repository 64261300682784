import { useDispatch } from "react-redux";
import { Box, Button, Container, Typography } from "@mui/material";

import { MessageCard, Page } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { logout } from "@APP/redux";

const UserErrorView = () => {
  const dispatch = useDispatch();

  const onTryAgain = () => {
    document.location.reload();
    // to avoid immediate navigation on dashboard before reloading
    document.location.replace(`${document.location.origin}${SCREEN_PATHS.DASHBOARD}`);
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="sm">
        <MessageCard type="error">
          <Typography variant="h4">Failure</Typography>
          <Typography>
            Sorry, we were unable to complete your request. Please, try again. If the problem
            persists, you can log out from the app to repeat the flow from the beginning.
          </Typography>
          <Box width="100%" mt={1} display="flex" justifyContent="flex-end">
            <Button onClick={() => dispatch(logout())} color="primary">
              Log out
            </Button>
            <Button onClick={() => dispatch(onTryAgain())} color="primary">
              Try Again
            </Button>
          </Box>
        </MessageCard>
      </Container>
    </Page>
  );
};

export default UserErrorView;
