import APP_LOGO from "./logo.svg";

interface Assets {
  IMAGES: {
    APP_LOGO: string;
  };
}

export const IMAGES: Assets["IMAGES"] = {
  APP_LOGO,
};

export default {
  IMAGES,
} as Assets;
