import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";

import { CHART_FILTER_FIELDS, REPORT_FILTERS, REPORT_NAMES } from "@APP/constants";
import { AppState } from "@APP/redux";
import {
  ChannelsReportData,
  DeclinedReportData,
  PeakTrafficReportData,
  Report,
  TransactionsReportData,
  UsersRegisteredByBankData,
  UsersRegisteredByErpData,
} from "@APP/types";
import { stripDuplicates } from "@APP/utils";

import { SelectListButton } from "./filterbuttons";
import { NewReportButton, NewReportPayload } from "./filterbuttons/NewReportButton";
import { ViewAllButton } from "./filterbuttons/ViewAllButton";

export type ChartHeaderButtonProps = {
  report: Report;
  reportName: keyof typeof REPORT_NAMES;
  chartFilters: CHART_FILTER_FIELDS[];
};

export type CommonChartButtonProps = {
  reportName: keyof typeof REPORT_NAMES;
  buttonLabel?: string;
  classes?: ClassNameMap;
  errorLabel?: string;
  field?: string;
};

export type ArraySetterFunctionType = (
  reportName: keyof typeof REPORT_NAMES,
  selectedData: Array<string>,
  reportFilter?: string,
) => void;

export type ArraySetterPayload = {
  field: string;
  dataArray: string[];
  selectedData?: string[];
  filterName: REPORT_FILTERS;
};

type FilterButtonsType = {
  newReportPayload: NewReportPayload;
  commonButtonProps: CommonChartButtonProps;
  filterProps: ArraySetterPayload[];
  report?: Report;
};

const useStyles = makeStyles((theme) => ({
  headerButtons: {
    display: "inline",
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    display: "inline-block",
  },
  actionButtons: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const FilterButtons = ({ newReportPayload, commonButtonProps, filterProps }: FilterButtonsType) => {
  const { classes } = commonButtonProps;

  return (
    <Box className={classes?.headerButtons}>
      <NewReportButton dataProps={newReportPayload} {...commonButtonProps} />
      {filterProps.map(({ field, dataArray, selectedData, filterName }) => (
        <SelectListButton
          key={field}
          field={field}
          dataArray={dataArray}
          selectedData={selectedData}
          filterName={filterName}
          errorLabel={`Please select at least one ${field}.`}
          {...commonButtonProps}
        />
      ))}
      <ViewAllButton buttonLabel="View All" dataProps={filterProps} {...commonButtonProps} />
    </Box>
  );
};

export const PeakTrafficHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as PeakTrafficReportData[];
  const { from, to } = report;

  const { selectedDates, selectedPartners } = useSelector(
    ({
      userAdmin: {
        reports: {
          peakTraffic: {
            filters: { selectedDates, selectedPartners },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
    }),
  );

  const reportDates = stripDuplicates(
    data.map(({ dateTimeBucket }) => dateTimeBucket.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));

  // field: "dates" is a string, as dates are generated from the report from various sources, and including in CHART_FILTER_FIELDS enum will cause errors
  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    chartFilters,
    report,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};

export const DeclinedHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as DeclinedReportData[];
  const { from, to } = report;

  const { selectedDates, selectedPartners } = useSelector(
    ({
      userAdmin: {
        reports: {
          declined: {
            filters: { selectedDates, selectedPartners },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};

export const TransactionsHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as TransactionsReportData[];
  const { from, to } = report;

  const { selectedDates, selectedPartners, selectedStatus } = useSelector(
    ({
      userAdmin: {
        reports: {
          transactions: {
            filters: { selectedDates, selectedPartners, selectedStatus },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
      selectedStatus,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));
  const reportStatuses = stripDuplicates(data.map(({ status }) => status));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
    {
      field: CHART_FILTER_FIELDS.status,
      selectedData: selectedStatus,
      dataArray: reportStatuses,
      filterName: REPORT_FILTERS.status,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};

export const ChannelsHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as ChannelsReportData[];
  const { from, to } = report;
  const { selectedDates, selectedPartners, selectedStatus, selectedChannels } = useSelector(
    ({
      userAdmin: {
        reports: {
          channels: {
            filters: { selectedDates, selectedPartners, selectedStatus, selectedChannels },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
      selectedStatus,
      selectedChannels,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportChannels = stripDuplicates(data.map(({ channel }) => channel));
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));
  const reportStatuses = stripDuplicates(data.map(({ status }) => status));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
    {
      field: CHART_FILTER_FIELDS.status,
      selectedData: selectedStatus,
      dataArray: reportStatuses,
      filterName: REPORT_FILTERS.status,
    },
    {
      field: CHART_FILTER_FIELDS.channel,
      selectedData: selectedChannels,
      dataArray: reportChannels,
      filterName: REPORT_FILTERS.channel,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};

export const UsersDeactiveHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as ChannelsReportData[];
  const { from, to } = report;
  const { selectedDates, selectedPartners } = useSelector(
    ({
      userAdmin: {
        reports: {
          usersDeactive: {
            filters: { selectedDates, selectedPartners },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};

export const UsersActiveHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as ChannelsReportData[];
  const { from, to } = report;
  const { selectedDates, selectedPartners } = useSelector(
    ({
      userAdmin: {
        reports: {
          usersActive: {
            filters: { selectedDates, selectedPartners },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};

export const UsersRegisteredByBankHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as UsersRegisteredByBankData[];
  const { from, to } = report;
  const { selectedDates, selectedPartners, selectedBanks } = useSelector(
    ({
      userAdmin: {
        reports: {
          usersRegisteredByBank: {
            filters: { selectedDates, selectedPartners, selectedBanks },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
      selectedBanks,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));
  const reportBanks = stripDuplicates(data.map(({ bank }) => bank));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
    {
      field: CHART_FILTER_FIELDS.bank,
      selectedData: selectedBanks,
      dataArray: reportBanks,
      filterName: REPORT_FILTERS.bank,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};
export const UsersRegisteredByErpHeaderButton = ({
  report,
  reportName,
  chartFilters,
}: ChartHeaderButtonProps) => {
  const classes = useStyles();
  const data = report.data as UsersRegisteredByErpData[];
  const { from, to } = report;
  const { selectedDates, selectedPartners, selectedErps } = useSelector(
    ({
      userAdmin: {
        reports: {
          usersRegisteredByErp: {
            filters: { selectedDates, selectedPartners, selectedErps },
          },
        },
      },
    }: AppState) => ({
      selectedDates,
      selectedPartners,
      selectedErps,
    }),
  );

  const reportDates: string[] = stripDuplicates(
    data.map(({ interval }) => interval.from.split("T")[0]),
  );
  const reportPartners = stripDuplicates(data.map(({ partner }) => partner));
  const reportErps = stripDuplicates(data.map(({ erp }) => erp));

  const filterProps: ArraySetterPayload[] = [
    {
      field: "dates",
      selectedData: selectedDates,
      dataArray: reportDates,
      filterName: REPORT_FILTERS.dates,
    },
    {
      field: CHART_FILTER_FIELDS.partner,
      selectedData: selectedPartners,
      dataArray: reportPartners,
      filterName: REPORT_FILTERS.partner,
    },
    {
      field: CHART_FILTER_FIELDS.erp,
      selectedData: selectedErps,
      dataArray: reportErps,
      filterName: REPORT_FILTERS.erp,
    },
  ];

  const newReportPayload: NewReportPayload = {
    from,
    to,
    report,
    chartFilters,
  };

  const commonButtonProps: CommonChartButtonProps = {
    classes,
    reportName,
  };

  return (
    <FilterButtons
      newReportPayload={newReportPayload}
      filterProps={filterProps}
      commonButtonProps={commonButtonProps}
    />
  );
};
