import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { logout } from "@APP/redux";
import { AppRouter, routes } from "@APP/navigation";
import { AppLoader } from "@APP/components";
import { AppLocalStorage, AppStorageKey } from "@APP/services";

export const RootComponent = () => {
  const dispatch = useDispatch();
  const [appReady, setAppReady] = useState(false);

  const localStorageHandler = useCallback((e: StorageEvent) => {
    const appStoreKey = e.key as AppStorageKey;
    if (appStoreKey === "auth.token" && !e.newValue) {
      dispatch(logout());
    }
  }, []);

  /**
   * Reloads page in case it's taken from bfcache
   */
  const onPageShowHandler = useCallback((e: PageTransitionEvent) => {
    if (e.persisted) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    (async () => {
      window.addEventListener("storage", localStorageHandler);
      window.addEventListener("pageshow", onPageShowHandler);

      const authToken = AppLocalStorage.getItem("auth.token");

      if (!authToken) dispatch(logout());
      setAppReady(true);
    })();

    return () => {
      window.removeEventListener("storage", localStorageHandler);
      window.removeEventListener("pageshow", onPageShowHandler);
    };
  }, []);

  return <>{appReady ? <AppRouter routes={routes} /> : <AppLoader />}</>;
};

export default RootComponent;
