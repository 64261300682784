export const ERROR_CODES = {
  // START: User admin customer error messages.
  2321: "No user matching supplied credentials found",
  // END: User admin customer error messages.
};

export const ERROR_MESSAGE_OVERRIDES = {
  // START: User admin customer error messages.
  2321: "Login details are invalid",
  // END: User admin customer error messages.
};

export const TOKEN_EXPIRED_ERROR_CODE = 5005;
