import CONFIG from "@APP/config";
import { LoginData } from "@APP/types";

import request from "../request";

export const login = async (email: string, password: string) => {
  const response = await request<LoginData>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/login`,
      data: { username: email, password },
    },
    { auth: false },
  );

  return response.data;
};

export default {
  login,
};
