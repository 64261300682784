import { Box, Button, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";

import { SCREEN_PATHS } from "@APP/navigation";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-4),
  },
  icon: {
    fontSize: 120,
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      textAlign="center">
      <Container className={classes.container} maxWidth="md">
        <NotListedLocationIcon className={classes.icon} color="primary" />
        <Typography className={classes.paragraph} align="center" color="textPrimary" variant="h1">
          404: Page not found
        </Typography>
        <Typography
          className={classes.paragraph}
          align="center"
          color="textPrimary"
          variant="subtitle2">
          You either came here by mistake or there was some kind of error. <br /> Please try using
          the navigation to get to the correct page.
        </Typography>
      </Container>
      <Button href={SCREEN_PATHS.ROOT} size="large" variant="contained" color="primary">
        Okay
      </Button>
    </Box>
  );
};

export default NotFoundView;
