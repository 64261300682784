import { Dispatch, SetStateAction } from "react";
import { Box, Card, TableCell, TableRow } from "@mui/material";

import { ScreenHeader, SortIcon } from "@APP/components";
import { OrgSortBy, SortType } from "@APP/types";
import { OrgTable } from "@APP/components/organisation";
import toCamelCase from "@APP/utils/toCamelCase";

import OrganisationRows from "./OrganisationRows";
import { Organisation, OrganisationData } from "./OrganisationDashboardView";

interface HeadCell {
  id: OrgSortBy;
  rightAlign: boolean;
  disablePadding: boolean;
  label: string;
}

interface OrganisationListProps {
  data: OrganisationData;
  entries: number;
  page: number;
  lastPage?: number;
  setPage?: (page: number) => void;
  setEntries?: (entries: number) => void;
  handleOnEntriesChange: (entries: number) => void;
  order?: "asc" | "desc";
  setOrder?: Dispatch<SetStateAction<"asc" | "desc">>;
  orderBy?: string;
  setOrderBy?: Dispatch<SetStateAction<string>>;
}

const HEADER_CELL_ITEMS: HeadCell[] = [
  { id: OrgSortBy.collapseIcon, rightAlign: true, disablePadding: true, label: "" },
  { id: OrgSortBy.orgName, rightAlign: true, disablePadding: false, label: "Name" },
  { id: OrgSortBy.status, rightAlign: true, disablePadding: false, label: "Status" },
  {
    id: OrgSortBy.subscriptionStatus,
    rightAlign: false,
    disablePadding: false,
    label: "Subscription Status",
  },
  {
    id: OrgSortBy.createdAt,
    rightAlign: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: OrgSortBy.createdAtTime,
    rightAlign: false,
    disablePadding: false,
    label: "Created Time",
  },
  {
    id: OrgSortBy.enableOrganisation,
    rightAlign: true,
    disablePadding: true,
    label: "Enable/Disable",
  },
];

const EMPTY_ROW_COL_SPAN = HEADER_CELL_ITEMS.length + 2;

const OrganisationList = ({
  data,
  entries,
  page,
  lastPage,
  setPage,
  handleOnEntriesChange,
  order,
  setOrder,
  orderBy,
  setOrderBy,
}: OrganisationListProps) => {
  const allowSorting = (label: string) => {
    return (
      (label === "Name" ||
        label === "Status" ||
        label === "Subscription Status" ||
        label === "Created Date" ||
        label === "Created Time") &&
      order
    );
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder?.(isAsc ? "desc" : "asc");
    setOrderBy?.(property);
  };

  const getLabel = (label: string) => {
    if (label === "Name") {
      return "orgName";
    } else if (label === "Created Date") {
      return "createdAt";
    } else if (label === "Created Time") {
      return "createdTime";
    } else {
      return toCamelCase(label);
    }
  };

  const renderHeader = () => (
    <TableRow>
      {HEADER_CELL_ITEMS.map(({ id, label, disablePadding }) => (
        <TableCell
          key={id}
          align="left"
          padding={disablePadding ? "none" : "normal"}
          sortDirection={order}
          onClick={() =>
            data?.data?.length && allowSorting(label) ? handleRequestSort(getLabel(label)) : null
          }
          style={{ cursor: data?.data?.length && allowSorting(label) ? "pointer" : "default" }}>
          <Box display="flex" alignItems="center" data-testid={`${label}-field-label`}>
            {label}
            {allowSorting(label) && !!data?.data?.length ? (
              <SortIcon active={orderBy === getLabel(label)} type={order as SortType} />
            ) : null}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );

  const RenderRows = ({
    orgId,
    orgName,
    status,
    subscriptionStatus,
    createdAt,
    users,
    order,
  }: Organisation) => {
    return (
      <OrganisationRows
        orgId={orgId}
        orgName={orgName}
        status={status}
        subscriptionStatus={subscriptionStatus}
        createdAt={createdAt}
        users={users}
        order={order}
      />
    );
  };

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={EMPTY_ROW_COL_SPAN} align="center">
        No Matching Organisations found.
      </TableCell>
    </TableRow>
  );

  return (
    <Box my={4}>
      <ScreenHeader title="Organisation" />
      <Card elevation={12}>
        <OrgTable
          renderHeader={renderHeader}
          data={data?.data || []}
          showPagination={true}
          renderRows={RenderRows}
          onEntriesChange={handleOnEntriesChange}
          onPageChange={setPage}
          entries={entries}
          page={page}
          lastPage={lastPage}
          renderEmptyDataRow={renderEmptyDataRow}
        />
      </Card>
    </Box>
  );
};

export default OrganisationList;
