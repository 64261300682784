import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const DetailPanel = ({ payer, email }: { payer: string; email?: string }) => (
  <Box margin={1}>
    <Table size="small" aria-label="additional rtp details">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Payer</TableCell>
          <TableCell>Payer Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>{payer}</TableCell>
          <TableCell>{email}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default DetailPanel;
