import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { AppState } from "@APP/redux";

import { SCREEN_PATHS } from "./paths";
import { RouteConfig } from "./routes";

interface Props extends RouteConfig {
  authenticated: boolean;
}

/**
 * Extended Route component.
 * Checks whether or not route is private, performs redirect to "Login" based on auth state.
 */
const AppRoute = ({
  authenticated,
  path,
  redirect,
  private: isPrivate,
  routes,
  component: Component,
  children,
}: Props) => {
  return (
    <Route
      path={path}
      children={children}
      render={(props) =>
        redirect ? (
          <Redirect to={redirect} />
        ) : isPrivate ? (
          authenticated ? (
            Component && <Component {...props} routes={routes} />
          ) : (
            <Redirect to={SCREEN_PATHS.LOGIN} />
          )
        ) : (
          Component && <Component {...props} routes={routes} />
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }: AppState) => ({ authenticated: auth.authenticated });

export default connect(mapStateToProps)(AppRoute);
