import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { SCREEN_PATHS } from "@APP/navigation";
import { AppState, login, logout } from "@APP/redux";
import { AuthForm, AuthLayout, CommonTextField, Page } from "@APP/components";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { formatErrorMessage } from "@APP/utils";

const loginValidationSchema = Yup.object().shape({
  username: Yup.string().max(255).required("Username is required"),
  password: Yup.string().max(255).required("Please enter your password"),
});

export const LoginView = () => {
  const { authenticated } = useSelector(({ auth: { authenticated } }: AppState) => ({
    authenticated,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const handleErrorCodes = useHandleErrorCodes();

  useEffect(() => {
    if (authenticated) dispatch(logout());
  }, []);

  const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: loginValidationSchema,
      onSubmit: async ({ username, password }: { username: string; password: string }) => {
        try {
          await dispatch(login(username, password));
          history.push(SCREEN_PATHS.USER_DASHBOARD);
        } catch (error: any) {
          const errorData = error?.response?.data;
          const isHandled = handleErrorCodes(errorData?.errorCode);

          if (isHandled) return;

          alert.open("Error", formatErrorMessage(errorData), [{ text: "Okay" }]);
        }
      },
    });

  const renderMainContent = () => (
    <AuthForm title="Sign in" subtitle="Sign in on the internal platform">
      <form onSubmit={handleSubmit}>
        <CommonTextField
          disabled={isSubmitting}
          error={Boolean(touched.username && errors.username)}
          fullWidth
          helperText={touched.username && errors.username}
          label="Username"
          margin="normal"
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.username}
          variant="outlined"
          inputProps={{ "data-testid": "username-input" }}
          data-testid="username-input-container"
        />
        <CommonTextField
          disabled={isSubmitting}
          error={Boolean(touched.password && errors.password)}
          fullWidth
          helperText={touched.password && errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          value={values.password}
          variant="outlined"
          inputProps={{ "data-testid": "password-input" }}
          data-testid="password-input-container"
        />
        <Box my={2}>
          <Button
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            data-testid="submit-button">
            Sign in
          </Button>
        </Box>
      </form>
    </AuthForm>
  );

  return (
    <Page title="Sign In" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

export default LoginView;
