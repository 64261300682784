import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIosIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardIosIcon from "@mui/icons-material/ChevronRight";

interface Props {
  /**
   * Renders logo and forward/backward navigation buttons.
   */
  renderHeader?: boolean;
  /**
   * Specifies RouterLink's "to" property for forward navigation button.
   * If empty, button will not be rendered.
   */
  forwardPath?: string;
  /**
   * Specifies RouterLink's "to" property for backward navigation button.
   * If empty, button will not be rendered.
   */
  backwardPath?: string;
  /**
   * Form title. If empty, the title will not be rendered.
   */
  title?: string;
  /**
   * Form subtitle. If empty, the subtitle will not be rendered.
   */
  subtitle?: string;
  /**
   * Specifies action property for backward navigation button.
   */
  backwardAction?: () => void;
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "100%",
    maxWidth: 540,
    padding: theme.spacing(4),
  },
  logo: {
    width: "100%",
    maxWidth: 100,
  },
}));

const AuthForm = ({
  backwardAction,
  renderHeader = true,
  forwardPath = "",
  backwardPath = "",
  title = "",
  subtitle = "",
  children,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.formContainer} elevation={12} {...props}>
      {renderHeader && (
        <Box mb={1} display="flex" alignItems="center">
          <Box flex={1} textAlign="left">
            {backwardAction && (
              <span onClick={() => backwardAction()}>
                <IconButton color="primary" size="large">
                  <ArrowBackIosIcon />
                </IconButton>
              </span>
            )}
            {backwardPath && (
              <RouterLink to={backwardPath}>
                <IconButton color="primary" size="large">
                  <ArrowBackIosIcon />
                </IconButton>
              </RouterLink>
            )}
          </Box>
          <Box flex={1} textAlign="right">
            {forwardPath && (
              <RouterLink to={forwardPath}>
                <IconButton color="primary" size="large">
                  <ArrowForwardIosIcon />
                </IconButton>
              </RouterLink>
            )}
          </Box>
        </Box>
      )}
      {(title || subtitle) && (
        <Box mb={1}>
          {title && (
            <Typography color="textPrimary" variant="h2">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography color="textSecondary" gutterBottom variant="body2">
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </Paper>
  );
};

export default AuthForm;
