import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Card, Container, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { formatInTimeZone } from "date-fns-tz";

import { FooterActionsButtons, Page, ScreenHeader, Table } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { OrganisationData, User } from "@APP/types";
import { getOrganizationById } from "@APP/services/api";
import { formatErrorMessage, getSubscriptionStatus } from "@APP/utils";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { hideLoader, showLoader } from "@APP/redux";
import { tableStyles as useStyles } from "@APP/styles";
import { commonFormatDate } from "@APP/constants";
import CONFIG from "@APP/config";
import { API } from "@APP/services";

const OrgDetailsView = () => {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { orgId }: { orgId: string } = useParams();
  const classes = useStyles();
  const handleErrorCodes = useHandleErrorCodes();
  const [orgDetails, setOrgDetails] = useState<OrganisationData | null>(null);

  const { subscriptionStatus } = orgDetails || {};

  const subscriptionLabel = ((status) => {
    switch (status) {
      case "Trial":
      case "Active":
        return "Deactivate";
      case "PendingCancellation":
      case "Cancelled":
        return "Activate";
      default:
        return "Change";
    }
  })(subscriptionStatus);

  const getOrgDetailsById = async () => {
    dispatch(showLoader());
    try {
      const details = await getOrganizationById(orgId);
      setOrgDetails(details);
    } catch (error) {
      alert.open("Error", formatErrorMessage(error), [
        { text: "Okay", onClick: () => history.push(SCREEN_PATHS.USER_DASHBOARD) },
      ]);
    } finally {
      dispatch(hideLoader());
    }
  };

  const changeSubscription = async () => {
    try {
      dispatch(showLoader());

      let subsId = "";
      const isActivate = subscriptionLabel === "Activate";

      if (isActivate) {
        const billingPlans = await API.getSubscriptionsBillingPlans(orgId);
        if (!billingPlans?.length) {
          throw new Error("No billing plans available");
        }
        const availablePlan = billingPlans.find((plan) => plan.available);
        subsId = availablePlan?.id ?? "";
      } else {
        const subscription = await API.getSubscriptionsByOrgId(orgId);
        subsId = subscription.id;
      }

      await API.changeOrganisationSubscriptionStatus(isActivate, orgId, subsId);
      getOrgDetailsById();
    } catch (error: any) {
      const errorData = error?.response?.data;
      const isHandled = handleErrorCodes(errorData?.errorCode);
      if (!isHandled) {
        alert.open("Error", formatErrorMessage(error));
      }
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleSubscription = () => {
    alert.open(
      "Confirm",
      `Are you sure you want to ${subscriptionLabel.toLocaleLowerCase()} this subscription?`,
      [{ text: "No" }, { text: "Yes", onClick: changeSubscription }],
    );
  };

  useEffect(() => {
    getOrgDetailsById();
  }, []);

  const renderHeader = () => (
    <TableRow className={classes.tableHead}>
      <TableCell>Username</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Created Date</TableCell>
      <TableCell>Created Time</TableCell>
    </TableRow>
  );

  const renderRows = ({ id, username, status, meta: { createdAt } }: User) => (
    <TableRow key={id}>
      <TableCell>{username}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        {createdAt ? formatInTimeZone(createdAt ?? "", "UTC", commonFormatDate) : "-"}
      </TableCell>
      <TableCell>{createdAt ? formatInTimeZone(createdAt ?? "", "UTC", "HH:mm") : "-"}</TableCell>
    </TableRow>
  );

  const renderEmptyDataRow = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        User Details not found.
      </TableCell>
    </TableRow>
  );

  return (
    <Container maxWidth={false}>
      <Page title="User Details">
        <Grid container>
          <Grid item xs={12} md={6}>
            <ScreenHeader title="View Organisation" />
          </Grid>
          {CONFIG.FEATURES?.SUBSCRIPTION &&
            orgDetails?.subscriptionStatus !== null &&
            orgDetails?.subscriptionStatus !== "Suspended" && (
              <Grid item xs={12} md={6}>
                <Box display="flex" textAlign="center" justifyContent="flex-end">
                  <Button variant="contained" color="primary" onClick={handleSubscription}>
                    {`${subscriptionLabel} Subscription`}
                  </Button>
                </Box>
              </Grid>
            )}
        </Grid>
        <form>
          <Box my={3}>
            <Card elevation={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} m="16px 16px 0px">
                  <Card elevation={2} sx={{ padding: "12px" }}>
                    <Grid container display="flex" spacing={1} mb={1}>
                      <Grid item display="flex" xs={12}>
                        <Grid item xs={12} md={2}>
                          <Typography variant="h6">Organisation Name:</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Typography variant="body2" color="textSecondary">
                            {orgDetails?.orgName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" spacing={1} mb={1}>
                      <Grid item display="flex" xs={12}>
                        <Grid item xs={12} md={2}>
                          <Typography variant="h6">Status:</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Typography variant="body2" color="textSecondary">
                            {orgDetails?.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" spacing={1} mb={1}>
                      <Grid item display="flex" xs={12}>
                        <Grid item xs={12} md={2}>
                          <Typography variant="h6">Subscription Status:</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Typography variant="body2" color="textSecondary">
                            {getSubscriptionStatus(orgDetails?.subscriptionStatus ?? "-")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" spacing={1} mb={1}>
                      <Grid item display="flex" xs={12}>
                        <Grid item xs={12} md={2}>
                          <Typography variant="h6">Created Date:</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Typography variant="body2" color="textSecondary">
                            {orgDetails?.createdAt
                              ? formatInTimeZone(
                                  orgDetails?.createdAt ?? "",
                                  "UTC",
                                  commonFormatDate,
                                )
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" spacing={1} mb={1}>
                      <Grid item display="flex" xs={12}>
                        <Grid item xs={12} md={2}>
                          <Typography variant="h6">Created Time:</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <Typography variant="body2" color="textSecondary">
                            {orgDetails?.createdAt
                              ? formatInTimeZone(orgDetails?.createdAt ?? "", "UTC", "HH:mm")
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Table
                    data={orgDetails?.users || []}
                    renderHeader={renderHeader}
                    renderRows={renderRows}
                    showPagination={false}
                    renderEmptyDataRow={renderEmptyDataRow}
                  />
                </Grid>
              </Grid>
              <Box p={2}>
                <Grid container item>
                  <Grid item lg={4} xs={false} />
                </Grid>
              </Box>
            </Card>
            <FooterActionsButtons backButtonText="Back" handleBackButton={() => history.goBack()} />
          </Box>
        </form>
      </Page>
    </Container>
  );
};

export default OrgDetailsView;
