import { ComponentType } from "react";
import { RouteProps } from "react-router-dom";

import {
  LoginView,
  LogoutView,
  NotFoundView,
  PlaceholderModalView,
  RTPDashboardView,
  UpdateUserSuccessView,
  UpdateUserView,
  UserDashboardView,
  UserDetailsView,
  UserErrorView,
  OrganisationDashboardView,
  OrgDetailsView,
} from "@APP/views";
// layout imports are required to be after views import to avoid bug
// eslint-disable-next-line
import { DashboardLayout, PageLayout } from "@APP/components/layout";

import { MODAL_PATHS, SCREEN_PATHS } from "./paths";

export interface RouteConfig {
  /**
   * Route path.
   * Must be `absolute` for regular routes and `relative` for modal routes.
   */
  path?: string | string[];
  /**
   * Whether to render path matches the `location.pathname` exactly.
   */
  exact?: boolean;
  /**
   * Component to render.
   */
  component?: ComponentType<any>;
  /**
   * JSX to render whether the path matches the location or not.
   */
  children?: RouteProps["children"];
  /**
   * A collection of child routes.
   * Being passed down to the route's `component` as a property.
   * Must be handled on a component level, e.g. by rendering an additional router.
   */
  routes?: RouteConfig[];
  /**
   * Location to redirect. The new location will override the current location in the history stack.
   */
  redirect?: string;
  /**
   * Whether unauthorized access to the route should be prevented.
   * Unauthenticated user will be redirected to login screen automatically.
   */
  private?: boolean;
  /**
   * Modal routes are relative: they appear on top of their parent routes.
   * It means that the parent route can't have `{ exact: true }` in order to support relative paths.
   */
  modal?: boolean;
}

/**
 * Centralized route configuration.
 */
export const routes: RouteConfig[] = [
  {
    path: SCREEN_PATHS.ROOT,
    component: DashboardLayout,
    private: true,
    routes: [
      {
        path: SCREEN_PATHS.USER_DASHBOARD,
        component: UserDashboardView,
      },
      {
        path: SCREEN_PATHS.ORGANISATION_DASHBOARD,
        component: OrganisationDashboardView,
      },
      {
        path: SCREEN_PATHS.UPDATE_USER_SUCCESS,
        component: UpdateUserSuccessView,
        exact: true,
      },
      {
        path: SCREEN_PATHS.UPDATE_USER,
        component: UpdateUserView,
        exact: true,
      },
      {
        path: SCREEN_PATHS.UPDATE_USER_DETAILS,
        component: UserDetailsView,
        exact: true,
      },
      {
        path: SCREEN_PATHS.ORGANISATION_DETAILS,
        component: OrgDetailsView,
        exact: true,
      },

      {
        path: SCREEN_PATHS.RTP_DASHBOARD,
        component: RTPDashboardView,
        exact: true,
      },
      {
        path: SCREEN_PATHS.APP_ERROR,
        component: UserErrorView,
      },
      {
        path: MODAL_PATHS.PLACEHOLDER,
        component: PlaceholderModalView,
        modal: true,
        exact: true,
      },
      {
        path: "*",
        redirect: SCREEN_PATHS.USER_DASHBOARD,
      },
    ],
  },
  {
    path: "/",
    component: PageLayout,
    routes: [
      {
        path: SCREEN_PATHS.LOGIN,
        component: LoginView,
      },
      {
        path: SCREEN_PATHS.LOGOUT,
        component: LogoutView,
      },
      {
        path: SCREEN_PATHS.NOT_FOUND,
        component: NotFoundView,
      },
      {
        path: "/",
        redirect: SCREEN_PATHS.DASHBOARD,
        exact: true,
      },
      {
        path: "*",
        redirect: SCREEN_PATHS.NOT_FOUND,
      },
    ],
  },
];
