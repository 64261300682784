import {
  Box,
  BoxProps,
  Table as MUITable,
  TableBody,
  TableContainer,
  TableHead,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PaginationPanel from "./OrgPagination";

interface Props<T> {
  /**
   * Array of data objects.
   */
  data: T[];
  /**
   * A function that returns the JSX elements for each row of the table.
   */
  renderHeader: () => JSX.Element;
  /**
   * A function that returns the JSX elements for each row of the table.
   */
  renderRows: (item: T, index: number) => JSX.Element;
  /**
   * Props for styling table container.
   */
  containerProps?: BoxProps;
  /**
   * Whether to render pagination footer - true by default.
   */
  showPagination?: boolean;
  /**
   * Number of rows per page.
   */
  entries?: number;
  /**
   * Zero-based index.
   */
  page?: number;
  /**
   * Handler for changing the entries value.
   */
  onEntriesChange?: (newEntries: number) => void;
  /**
   * Handler for changing pages.
   */
  onPageChange?: (newPage: number) => void;
  /**
   * A number that indicates the last page.
   */
  lastPage?: number;
  /**
   * Props for styling table container. When filtering is enable and data is empty
   */
  renderEmptyDataRow?: () => JSX.Element | undefined;
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    height: "100%",
  },
  paginationPanelItem: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.action.active,
      color: theme.palette.secondary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.action.active,
        opacity: 0.6,
      },
    },
  },
}));

const OrgTable = <T,>({
  data,
  renderHeader,
  renderRows,
  showPagination = true,
  page = 0,
  entries = 15,
  lastPage = 1,
  onEntriesChange,
  onPageChange,
  containerProps = {},
  renderEmptyDataRow,
}: Props<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDisplayMoreMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box {...containerProps}>
      <TableContainer className={classes.container}>
        <MUITable stickyHeader={isDisplayMoreMd} className={classes.table}>
          <TableHead>{renderHeader()}</TableHead>
          <TableBody>
            {!data.length && !!renderEmptyDataRow ? renderEmptyDataRow() : data.map(renderRows)}
          </TableBody>
        </MUITable>
      </TableContainer>

      {showPagination && (
        <PaginationPanel
          entries={entries}
          onEntriesChange={onEntriesChange}
          rowsPerPageOptions={[25, 50, 100]}
          lastPage={lastPage}
          page={page}
          onPageChange={onPageChange}
        />
      )}
    </Box>
  );
};

export default OrgTable;
