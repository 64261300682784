import {
  Partners,
  REPORT_TRANSACTION_STATUSES,
  ReportGroupIntervals,
  TimeBuckets,
  REPORT_ERPS,
  MetroBankAccountStatuses,
} from "@APP/constants";

export interface LoginData {
  token: string;
  emailVerified: boolean;
  phoneVerified: boolean;
}

export interface UserInfoData {
  username: string;
  phone: string;
  orgName?: string;
  roles: string[];
  org: null | UserOrganisation;
  verifiedEmail: boolean;
}

export interface UserOrganisation {
  id: string;
  name: string;
  companyInfo: ContactInfo;
  primaryContact: ContactInfo;
  otherContacts: any[];
  taxInfo: {
    vatRegistrationNumber?: string;
    utrNumber: string;
    defaultVatRate: string;
    defaultCurrency: string;
  };
  SIC: string[];
  logo: string;
  orgType: string;
  pricingPlan: string;
  registrationDate?: string;
}

export interface ContactInfo {
  id: string;
  name: string;
  email: string;
  telephone: string;
  address: {
    lines: string[];
    postcode: string;
    city: string;
    country: string;
  };
}

export interface Contact {
  id?: ResourceId;
  name: string;
  email?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  vatRate?: VATRate;
  mobile?: string;
  bankDetails?: BankDetails;
}

export type BankDetails = {
  identification: string;
  schemeName: string;
};

export type ResourceId = {
  source?: string;
  externalId: string;
};

export type Address = {
  addressLines?: string[];
  postcode?: string;
  city?: string;
  countryId?: string;
  state?: string;
};
export interface Custodian {
  country: string;
  features: string[];
  fullName: string;
  id: string;
  logo: string;
  shortName: string;
  type: string;
  website: string;
  tags?: string[];
  description?: string;
  provider: string;
}

export type CustodianType = "Bank" | "AccountingPackage";

export type ERPConsentInitData = {
  redirectUrl?: string;
  consentId: string;
};

export type ERPConsentData = {
  consentId: string;
  context: {
    custodianId: string;
    customerId: string;
    status: string;
    created: string;
    consentType: string;
    externalId: string;
  };
  consentBody: string;
};

export type VATRate =
  | "ECServicesZeroRated"
  | "ReverseChargeServices"
  | "ReverseCharge"
  | "ECGoodsZeroRated"
  | "Standard"
  | "ReverseChargePhonesAndComputerChips"
  | "ExemptFromVAT"
  | "ECGoodsStandard"
  | "ZeroRated"
  | "ECServicesStandard"
  | "Reduced"
  | "NoVATApplicable";

export type InvoiceStatus = "Draft" | "Approved" | "Void" | "Sent" | "Paid" | "PartiallyPaid";

export enum TypeInvoice {
  Payable = "Payable",
  Receivable = "Receivable",
}

export type TaxCategory = {
  name: string;
  amount: Amount;
};

export interface InvoiceLineItem {
  id: string;
  amountTaxExclusive: Amount;
  category: string;
  description: string;
  vatRate: VATRate;
  ledgerCode?: number;
}

export type Invoice = {
  entityDetails: {
    source: string;
    externalId: string;
  };
  lineItems: InvoiceLineItem[];
  dateTimeIssued: Date;
  dueDateTime: Date;
  reference: string;
  totalAmountTaxExclusive: Amount;
  totalAmountTaxInclusive: Amount;
  paidDate: string;
  plannedPaymentDate: string;
  status: InvoiceStatus;
  attachments?: [
    {
      id: string;
      name: string;
      uri: string;
      contentType: string;
      createdAt: string;
    },
  ];
  notes: string[];
  name: string;
  description: string;
};

export type Receivable = Invoice & {
  id: string;
  customerContact: Contact;
  invoiceType: TypeInvoice.Receivable;
  remainingAmountTaxExclusive: Amount;
  taxCategories?: TaxCategory[];
};

export type Payable = Invoice & {
  supplierContact: Contact;
  debtorContact: Contact;
  invoiceType: TypeInvoice.Payable;
};

export type User = {
  id?: string;
  username: string;
  meta: { createdAt: string; lastLoginTime?: null | string };
  createdAt?: string;
  createdAtTime?: string;
  orgName: string;
  orgId: string;
  status?: string;
  roles: string[];
  phone?: string;
};

export type RTP = {
  amount: Amount;
  channel: RTPDeliveryChannel;
  customer: Contact;
  details: {
    externalId: string;
    source: string;
  };
  id: string;
  scheduledDates?: string[];
  status: RTPStatus;
  supplier: {
    SIC: string[];
    companyInfo: {
      email: string;
      id: string;
      name: string;
      telephone: string;
    };
    name: string;
    otherContacts: [];
    primaryContact: {
      id: string;
      name: string;
      email?: string;
      telephone?: string;
    };
    taxInfo: {
      defaultCurrency: string;
      defaultVatRate: string;
      utrNumber: string;
      vatRegistrationNumber?: string;
    };
  };
  supplierBankDetails: {
    accountNumber: string;
    scheme: string;
    bankId: string;
  };
  receivable: {
    lineItems: Receivable["lineItems"];
    dueDateTime: Receivable["dueDateTime"];
    dateTimeIssued: Receivable["dateTimeIssued"];
    reference: Receivable["reference"];
    customerEmail: string;
    customerMobile: string;
  };
  deliveryDates?: string[];
  reminders?: {
    channel: string;
    enabled: boolean;
    tpe: string;
  }[];
  standingOrder?: StandingOrder;
  paymentLink?: string;
  createdAt?: string;
  rtpCreationUserZone?: string; // older RTP's won't have this prop so it's optional
  history?: {
    status: RTPStatus;
    timestamp: string;
  }[];
};

export enum RTPStatus {
  RequestMade = "RequestMade",
  PaymentSent = "PaymentSent",
  PaymentReceived = "PaymentReceived",
  Declined = "Declined",
  Created = "Created",
  Scheduled = "Scheduled",
  Cancelled = "Cancelled",
  InProgress = "InProgress",
}

export enum RTPDeliveryChannel {
  Email = "Email",
  Sms = "Sms",
  OwnSms = "OwnSms",
  FaceToFace = "FaceToFace",
  WhatsApp = "WhatsApp",
}

export type DueDate = "Overdue" | "DueToday";

export type RTPQueryFilter = {
  dueDate?: DueDate;
  status?: keyof typeof RTPStatus | (string & {});
};

/**
 * Common pagination options used across API methods.
 */
export interface RequestPagination {
  page?: number;
  entries?: number;
}

export interface Amount {
  amount: string;
  currency: string;
}

export enum SortBy {
  dueDate = "due_date",
  amount = "amount",
}

export interface RequestSorting {
  sort_type?: SortType;
  sort_by?: SortBy;
}

export enum SortType {
  asc = "asc",
  desc = "desc",
}

/**
 * A set of pagination links returned in the API response.
 */
export interface ResponsePagination {
  self?: string;
  first?: string;
  prev?: string;
  next?: string;
  last?: string;
}

export interface StandingOrder {
  id?: string;
  reference: string;
  frequency: InstalmentFrequency;
  numberOfPayments?: number;
  firstPaymentAmount: Amount;
  recurringPaymentAmount?: Amount;
  finalPaymentAmount?: Amount;
  firstPaymentDate?: string;
  recurringPaymentDate?: string;
  finalPaymentDate?: string;
}

export enum InstalmentFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export type UserStatus = "Active" | "Deactivated";

export enum ADMIN_ACTIONS {
  Block = "Block",
  Unblock = "Unblock",
  DeleteMetroBank = "DeleteMetroBank",
  EnableMetroBank = "EnableMetroBank",
  DisableMetroBank = "DisableMetroBank",
}

export type UserInfoDataExtended = UserInfoData & {
  customerId: string;
  meta: {
    lastLoginTime: string;
  };
  password?: string;
  blocked?: boolean;
  userActive?: string;
  linkedBank?: string;
  comments?: string;
};

export type UserSearchResponse = {
  username: string;
  customerId: string;
  orgId: string;
  orgName: string;
  roles: string[];
  phone: string | undefined;
  status?: string;
  meta: {
    createdAt: string;
    lastLoginTime?: null | string;
  };
};

// orgname is not camelcase as in request it is lowercase.
export type UserSearchTerms = {
  username?: string;
  orgname?: string;
  phone?: string;
  from?: string;
  to?: string;
};

export type OrganisationSearchTerm = {
  from?: string;
  to?: string;
  orgname?: string | undefined;
};

export interface OrganisationData {
  orgId: string;
  orgName: string;
  status: string;
  subscriptionStatus: string;
  users: User[];
  createdAt?: string;
  subscriptionId?: string;
}

export interface OrganisationSearchedData {
  data: OrganisationData[];
  meta?: {
    totalItems: number;
  };
}

export type ChartData = {
  name: string;
  value: number;
  color?: string;
};

export type LineData = { name: keyof typeof TimeBuckets } & {
  [key in string]: number;
}[];

export type AdminRTPFilter = {
  receivableId?: string;
  source?: string;
  requestId?: string;
  from: string;
  to: string;
};

export enum RtpSortBy {
  email = "email",
  createdAt = "createdAt",
  createdAtTime = "createdAtTime",
  amount = "amount",
  status = "status",
  dueDateTime = "dueDateTime",
  firstDeliveryDate = "firstDeliveryDate",
  firstDeliveryTime = "firstDeliveryTime",
  channel = "channel",
}

export enum UserSortBy {
  username = "username",
  orgName = "orgName",
  connectedBank = "connectedBank",
  status = "status",
  createdAt = "createdAt",
  createdAtTime = "createdAtTime",
}

export enum OrgSortBy {
  collapseIcon = "collapseIcon",
  orgName = "orgName",
  phoneNumber = "Phone Number",
  connectedBank = "connectedBank",
  orgStatus = "orgStatus",
  subscriptionStatus = "subscriptionStatus",
  createdAt = "createdAt",
  createdAtTime = "createdAtTime",
  username = "username",
  status = "status",
  userRole = "userRole",
  lastLoginTime = "lastLoginTime",
  enableOrganisation = "enableOrganisation",
}

export type ReportInterval = {
  from: string;
  to: string;
};

export type Partner = keyof typeof Partners;

export type ReportGroupInterval = keyof typeof ReportGroupIntervals;
export type ReportTransactionStatus = keyof typeof REPORT_TRANSACTION_STATUSES;
export type ReportTransactionChannel = keyof typeof RTPDeliveryChannel;
export interface ActivityReportRequestFilter {
  from: string;
  to: string;
  partner?: Partner[];
  group?: ReportGroupInterval;
}

export type ActivityReportFilters = {
  selectedDates: string[];
  selectedPartners: Partner[];
  selectedStatus?: ReportTransactionStatus[];
  selectedChannels?: RTPDeliveryChannel[];
  selectedErps?: REPORT_ERP[];
  selectedBanks?: string[];
};

export type ActivityReportResponse = ActivityReportRequestFilter & {
  data: ReportDataType[];
};

export type Report = ActivityReportResponse & {
  group?: ReportGroupInterval;
  filters: ActivityReportFilters;
};

export type CommonReportData = {
  total: number;
  partner: keyof typeof Partners;
  group?: ReportGroupInterval;
};

export type PeakTrafficReportData = CommonReportData & {
  dateTimeBucket: string;
};

export type DeclinedReportData = CommonReportData & {
  interval: ReportInterval;
};

export type TransactionsReportData = DeclinedReportData & {
  status: ReportTransactionStatus;
};

export type ChannelsReportData = TransactionsReportData & {
  channel: RTPDeliveryChannel;
};

export type UsersActiveReportData = DeclinedReportData;
export type UsersDeactiveReportData = DeclinedReportData;
export type UsersRegisteredByBankData = DeclinedReportData & {
  bank: string;
};
export type UsersRegisteredByErpData = DeclinedReportData & {
  erp: string;
};

export type PeakTrafficDataResponse = {
  totalSent: number;
  dateTimeBucket: string;
  partner: keyof typeof Partners;
};

export type RtpReportData = Partial<ChannelsReportData> &
  Required<DeclinedReportData> & {
    bank?: string;
    erp?: string;
  };

export type DateRangeButton = {
  label: string;
  from: string;
  to: string;
  active: boolean;
};

export type MetroBankAccountDetails = {
  accountNumber: string;
  customerName: string;
  sortCode: string;
  returnedCustomerName: string;
  resultText: string;
  nameMatchResult: string;
  accountTypeResult: boolean;
  result: boolean;
  customerId: string;
  status: MetroBankAccountStatuses;
};

export type ReportDataType =
  | DeclinedReportData
  | TransactionsReportData
  | ChannelsReportData
  | PeakTrafficReportData
  | UsersDeactiveReportData
  | UsersActiveReportData
  | UsersRegisteredByBankData
  | UsersRegisteredByErpData;

export type REPORT_ERP = keyof typeof REPORT_ERPS;

export type DateDefaultOptions = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
};

export interface SubscriptionPlan {
  id: string;
  freeTrialDays: number;
  periodDays: number;
  price: {
    amount: string;
    currency: string;
  };
  available: Boolean;
}

export interface SubscriptionDetails {
  endDate: string;
  id: string;
  orgId: string;
  planId: string;
  price: {
    amount: string;
    currency: string;
  };
  status: string;
}
