/**
 * Keys for data stored in the Session Storage.
 */
export type AppSessionStorageKey = "modal.dismissed";

export const getItem = (key: AppSessionStorageKey) => sessionStorage.getItem(key);
export const setItem = (key: AppSessionStorageKey, value: string) =>
  sessionStorage.setItem(key, value);
export const removeItem = (key: string) => sessionStorage.removeItem(key);

/**
 * A typesafe wrapper for the methods provided by browser's Session Storage API.
 * Only the keys listed in "AppSessionStorageKey" can be used as arguments to access the data in Session Storage.
 */
const AppSessionStorage = {
  getItem,
  setItem,
  removeItem,
};

export default AppSessionStorage;
