import { ElementType, MouseEvent } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, ListItem, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

interface Props {
  className?: string;
  href: string;
  icon?: ElementType;
  title: string;
  isFullMenu: boolean;
  onClick?: (event: MouseEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(2),
  },

  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.action.active,
    },
    "& $title": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

const NavItem = ({ className, href, icon: Icon, title, isFullMenu, onClick, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
      <Tooltip title={title} placement="right" disableHoverListener={isFullMenu}>
        <Button
          color="primary"
          variant="text"
          fullWidth
          activeClassName={classes.active}
          component={RouterLink}
          onClick={onClick}
          to={href}>
          {Icon && <Icon className={classes.icon} size="20" />}
          {isFullMenu && <span className={classes.title}>{title}</span>}
        </Button>
      </Tooltip>
    </ListItem>
  );
};

export default NavItem;
