import { Dispatch } from "redux";
import { createActionCreator } from "deox";

import { OrganisationSearchedData } from "@APP/types";

export const setOrganisationSearchResults = createActionCreator(
  "@organisationAdmin/SET_ORGANISATION_SEARCH_RESULTS",
  (resolve) => (results: OrganisationSearchedData | null) => resolve(results),
);

export const resetOrgSearchResults = createActionCreator(
  "@organisationAdmin/RESET_SEARCH_RESULTS",
  (resolve) => () => resolve(),
);

export const clearOrgSearchResults = () => (dispatch: Dispatch) => {
  dispatch(resetOrgSearchResults());
  dispatch(setOrganisationSearchResults(null));
};
