import { createStore, applyMiddleware, compose, Store } from "redux";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createFilter } from "redux-persist-transform-filter";
import { createStateSyncMiddleware } from "redux-state-sync";

import rootReducer from "../reducers";

const authFilter = createFilter("auth", ["authenticated"]);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  transforms: [authFilter],
  stateReconciler: autoMergeLevel2,
};

const stateSyncConfig = {
  // dashboardApp will not be triggered in other tabs
  blacklist: ["dashboardApp", "persist/PERSIST"],
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);
const configureStore = (): Store => {
  const middlewares = [thunk, createStateSyncMiddleware(stateSyncConfig)];

  return createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));
};

export type AppState = ReturnType<typeof rootReducer>;
export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore();
export const persistor = persistStore(store);

export default store;
